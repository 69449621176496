import React, { useState, useEffect } from 'react';
import { Table, Space, Button, Typography, notification, Modal, Avatar, Input ,Row,Col,Form} from 'antd';
import { useHistory } from 'react-router-dom';
import ajaxService from '../../../services/ajax-service';
import { DeleteOutlined, CloseCircleOutlined } from '@ant-design/icons';

const { Title, Text } = Typography;


export default function Product() {

  const [dataSource, setDataSource] = useState([])
  const [productName, setProductName] = useState('')
  const [showModal, setShowModal] = useState(false);

  const history = useHistory();

  const editProduct = (record) => {
    if (record.has_variant == 0) {
      console.log(record.has_variant)
      history.push({ pathname: "/products/form/" + record.id })
    } else {
      history.push({ pathname: "/products/variant/" + record.id })

    }
  }
  const columns = [

    // {
    //   title: 'ID',
    //   dataIndex: 'id',
    //   key: 'id',
    //   fixed: 'left',
    // },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',

    },
    {
      title: 'Image',
      dataIndex: 'image',
      key: 'image',
      render: (image) => <Avatar shape="square" size={64} src={image} />

    },
    {
      title: 'SKU',
      dataIndex: 'sku',
      key: 'sku',

    },
    {
      title: 'Has Variants',
      dataIndex: 'has_variant',
      key: 'has_variant',
      render: (has_variant, row) => <Text>{has_variant == 1 ? 'Yes' : 'No'}</Text>,


    },
    {
      title: 'Stock',
      dataIndex: 'stock',
      key: 'stock',

    },
    {
      title: 'Selling Price',
      dataIndex: 'selling_price',
      key: 'selling_price',

    },

    {
      title: 'Active',
      dataIndex: 'active',
      key: 'active',
      render: (active, row) => <Text>{active == 1 ? 'Yes' : 'No'}</Text>,

    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      fixed: 'right',
      width: 100,
      render: (text, record) => (
        <Space size='small'>
          <Button onClick={() => editProduct(record)}>Edit</Button>
          <Button onClick={() => openDeleteModal(record.id)}>Delete</Button>
        </Space>
      ),
    },
  ];


  const initialize = async () => {
    const response = await ajaxService.get('products');
    if (response !== undefined) {

      if (response.status === 200) {
        setDataSource(response.data);
      }
    }
  }
  const deleteProduct = async () => {

    let response = await ajaxService.delete('products/' + localStorage.getItem('product'));
    setShowModal(false)
    if (response.status === 200) {
      initialize();
      notification.open({ message: 'Product Deleted Successfully...', icon: <DeleteOutlined style={{ color: '#108ee9' }} /> })
    } else {
      notification.open({ message: 'Product Not Deleted...', icon: <CloseCircleOutlined style={{ color: 'red' }} /> })

    }
  }
  const openDeleteModal = (id) => {
    localStorage.setItem('product', id);
    setShowModal(true)
  }

  const handleSearch = async () => {
    var formData = new FormData();

    if (productName !== '') {
      formData.append('name', productName);
    }

    const response = await ajaxService.post('products/filter', formData);

    if (response.status !== undefined) {
      setDataSource(response.data);
    } else {
      notification.open({ message: 'No Product Found...', icon: <CloseCircleOutlined style={{ color: 'red' }} /> })
    }

  }

  useEffect(() => {
    initialize();
  }, [])

  return (
    <div>
      <Form
        layout={'vertical'}
      >
        <Row gutter={16} >
          <Col span={6}>
            <Form.Item label="Search By Product Name" >
              <Input value={productName} onChange={(e) => setProductName(e.target.value)} />
            </Form.Item>
          </Col>
          <Col span={6} className="gutter-row">
            <Form.Item label='&nbsp;'>
              <Space>
                <Button onClick={handleSearch}>Search</Button>
                <Button onClick={()=>setProductName('')}>Clear</Button>
              </Space>
            </Form.Item>
          </Col>
        </Row>
       
      </Form>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Title level={3}>Products</Title>
        <Space >
          <Button type="primary" onClick={() => history.push({ pathname: "/products/form/0" })} >Add Product</Button>
          <Button type="primary" onClick={() => history.push({ pathname: "/products/variant/0" })} >Add Variant Product</Button>
        </Space>
      </div>
      <Table
        dataSource={dataSource}
        columns={columns}
      />
      <Modal
        title="Delete Product"
        centered
        visible={showModal}
        onCancel={() => setShowModal(false)}
        footer={[
          <Button key="delete" onClick={() => deleteProduct()}>
            Delete
          </Button>
          ,
          <Button key="back" onClick={() => setShowModal(false)}>
            Close
          </Button>
        ]}
      >
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <p>Are you sure you want to delete this product?</p>
        </div>
      </Modal>
    </div>
  )


}



