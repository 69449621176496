import React, { useState, useCallback, useEffect } from 'react';
import { Form, Input, InputNumber, Button, Space, Row, Col, Checkbox, Select, notification, message } from 'antd';
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"
import { CKEditor } from "@ckeditor/ckeditor5-react"
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { useHistory, useParams } from "react-router-dom";
import ajaxService from '../../../services/ajax-service';
import ImageUpload from '../../../components/image-upload';
const { Option } = Select
const { TextArea } = Input

const ProductForm = () => {
    const [categories, setcategories] = useState([])
    const [brands, setBrands] = useState([])
    const [fileList, setFileList] = useState([]);
    const [imagesList, setImagesList] = useState([]);
    const [text, setText] = useState("")
    const [form] = Form.useForm();
    let history = useHistory();
    let { id } = useParams();

    const onChange = ({ fileList: newFileList }) => {

        setFileList(newFileList);
        let list = []

        newFileList.map((item) => {
            if (item.response !== undefined) {
                list.push({
                    uid: item.response.path,
                    name: 'image.png',
                    status: 'done',
                    url: item.response.path,
                    preview: false
                })
            }
        })
        setImagesList(list)

    };

    const onFinish = async (values) => {
        if (text === '' && fileList.length === 0 && imagesList.length === 0) {
            return message.error('Please fill all required fields.')
        } else {

            let imageData = [];

            imagesList.map((item) => {
                if (item.url !== '') {
                    imageData.push(item.url)
                }
            })

            let data = {
                description: text,
                short_description: values.short_description,
                name: values.name,
                image: imageData,
                category_id: 0,//values.category_id,
                stock: values.stock,
                unlimited_stock: values.unlimited_stock,
                heigth: values.heigth,
                breath: values.breath,
                selling_price: values.selling_price,
                length: values.length,
                weight: values.weight,
                brand_id: 0,//values.brand_id,
                cost_price: values.cost_price,
                meta_title: values.meta_title,
                meta_description: values.meta_description,
                sku: values.sku,
                active: values.active,
                has_variant: false,
                is_main: true,
                offer: true,
                og_title : values.og_title,
                og_description : values.og_description,
                og_url : values.og_url,
                twitter_title : values.twitter_title,
                twitter_description : values.twitter_description,
                twitter_url : values.twitter_url,
                twitter_summary : values.twitter_summary                
            }

            if (id == 0) {

                const response = await ajaxService.post('products', data);

                if (response !== undefined) {
                    if (response.status === 200) {
                        history.push({ pathname: "/products" });
                        notification.open({ message: 'Product Added Successfully...', icon: <CheckCircleOutlined style={{ color: '#108ee9' }} /> })
                    } else {
                        notification.open({ message: 'Product Not Added...', icon: <CloseCircleOutlined style={{ color: 'red' }} /> })
                    }
                }

            } else {

                let list = [...imageData]

                fileList.map((item) => {
                    if (item.url !== undefined) {
                        list.push(item.url)
                    }
                })

                data.image = list;
                data.active = values.active

                const response = await ajaxService.put('products/' + id, data);

                if (response.status === 200) {
                    history.push({ pathname: "/products" });
                    notification.open({ message: 'Product updated successfully...', icon: <CheckCircleOutlined style={{ color: '#108ee9' }} /> })
                } else {
                    notification.open({ message: 'Unable to update product', icon: <CloseCircleOutlined style={{ color: 'red' }} /> })

                }
            }
        }
    };

    const onFinishFailed = errorInfo => {
        console.log('Failed:', errorInfo);
    };

    const loadCategories = useCallback(async () => {
        const response = await ajaxService.get('categories');
        if (response !== undefined) {

            if (response.status === 200) {
                setcategories(response.data);
            }
        }
    }, [])
    const loadBrands = useCallback(async () => {
        const response = await ajaxService.get('brands');
        const { status, data } = response;

        if (status === 200) {
            setBrands(data);
        }
    }, [])

    const loadData = useCallback(async (id) => {
        if (id > 0) {
            let response = await ajaxService.get('products/' + id);
            if (response.data != null) {
                form.setFieldsValue({ ...response.data });
                form.setFieldsValue({ category_id: parseInt(response.data.category_id) })
                form.setFieldsValue({ brand_id: parseInt(response.data.brand_id) })
                console.log(response)
                setText(response.data.description)
                let images = []
                response.data.images.map((v) => {

                    let imageItem = {
                        uid: v.id,
                        name: 'image.png',
                        status: 'done',
                        url: v.url,
                        preview: false
                    }
                    images.push(imageItem)
                })


                setFileList(images);
            }
        }
    }, [])

    useEffect(() => {
        loadCategories();
        loadBrands();
        loadData(id);
    }, []);


    return (
        <Form
            layout={'vertical'}
            // initialValues={initialValues}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            form={form}
        >
            <Row gutter={16} >
                <Col span={3}></Col>
                <Col span={18}>
                    <Row gutter={16} >
                        <Col span={16}  >
                            <Form.Item name='name' label="Name" rules={[
                                {
                                    required: true,
                                    message: 'Product Name is required',
                                },
                            ]}>
                                <Input placeholder="product name" />
                            </Form.Item>

                        </Col>
                        <Col span={8} >
                            <Form.Item name='meta_title' label="Meta Title" rules={[
                                {
                                    required: true,
                                    message: 'Meta Title is required',
                                },
                            ]} >
                                <Input placeholder="meta title" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={16}>
                            <Form.Item name='short_description' label="Short Description" rules={[
                                {
                                    required: true,
                                    message: 'Short Description is required',
                                },
                            ]}>
                                <TextArea rows={3} maxLength={300} placeholder="maxLength is 300" />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item name='meta_description' label="Meta Description" rules={[
                                {
                                    required: true,
                                    message: 'Meta Description is required',
                                },
                            ]}>
                                <Input placeholder="meta description" />
                            </Form.Item>
                            <Form.Item name="active" valuePropName="checked">
                                <Checkbox>Active</Checkbox>
                            </Form.Item>

                            <Form.Item name='unlimited_stock' valuePropName="checked">
                                <Checkbox>Unlimited Stock</Checkbox>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item name='description' label="Description" >
                                <CKEditor
                                    className='editor'
                                    editor={ClassicEditor}
                                    data={text}
                                    onChange={(event, editor) => {
                                        const data = editor.getData()
                                        setText(data)
                                    }}
                                />
                            </Form.Item>
                        </Col>

                    </Row>
                    <Row>

                    </Row>
                    <Row gutter={16}>
                        <Col span={6}>
                            <Form.Item name='sku' label="SKU" rules={[
                                {
                                    required: true,
                                    message: 'SKU is required',
                                },
                            ]}>
                                <Input placeholder="" />
                            </Form.Item>
                        </Col>
                        <Col span={6}> <Form.Item name='stock' label="Stock" >
                            <InputNumber placeholder="" />
                        </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item name='cost_price' label="Cost Price" rules={[
                                {
                                    required: true,
                                    message: 'Cost Price is required',
                                },
                            ]}>
                                <InputNumber placeholder="" step="0.01" />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item name='selling_price' label="Selling Price" rules={[
                                {
                                    required: true,
                                    message: 'Selling Price is required',
                                },
                            ]}>
                                <InputNumber placeholder="" step="0.01" />
                            </Form.Item>

                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={6}><Form.Item name='weight' label="Weight in grams">
                            <InputNumber placeholder="weight" />
                        </Form.Item>
                        </Col>
                        <Col span={6}> <Form.Item name='length' label="Lenght in Inches">
                            <InputNumber placeholder="length" />
                        </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item name='breath' label="Breath in Inches">
                                <InputNumber placeholder="breath" />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item name='heigth' label="Heigth in Inches">
                                <InputNumber placeholder="heigth" />
                            </Form.Item>

                        </Col>

                    </Row>
                    <Row >
                        <Col span={24}>
                            <Form.Item label="Upload Image" name="file" >
                                <ImageUpload onChange={onChange} fileList={fileList} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <h2>SEO <small><em>(Optional)</em></small></h2>
                    <hr />

                    <Row gutter={16}>
                        <Col span={8} >
                            <Form.Item name='og_title' label="Open Graph Title" rules={[
                                {
                                    required: false,
                                    message: 'Open Graph Title is required',
                                },
                            ]} >
                                <Input placeholder="Facebook open graph title" />
                            </Form.Item>
                        </Col>
                        <Col span={8} >
                            <Form.Item name='og_description' label="Open Graph Description" rules={[
                                {
                                    required: false,
                                    message: 'Open Graph Description is required',
                                },
                            ]} >
                                <Input placeholder="Facebook open graph description" />
                            </Form.Item>
                        </Col>
                        <Col span={8} >
                            <Form.Item name='og_url' label="Open Graph Url" rules={[
                                {
                                    required: false,
                                    message: 'Open Graph Url is required',
                                },
                            ]} >
                                <Input placeholder="Facebook open graph url" type="url" />
                            </Form.Item>
                        </Col>

                        <Col span={8} >
                            <Form.Item name='twitter_title' label="Twitter Title" rules={[
                                {
                                    required: false,
                                    message: 'Twitter title is required',
                                },
                            ]} >
                                <Input placeholder="Twitter title" />
                            </Form.Item>
                        </Col>
                        <Col span={8} >
                            <Form.Item name='twitter_description' label="Twitter Description" rules={[
                                {
                                    required: false,
                                    message: 'Twitter description is required',
                                },
                            ]} >
                                <Input placeholder="Twitter description" />
                            </Form.Item>
                        </Col>
                        <Col span={8} >
                            <Form.Item name='twitter_url' label="Twitter Url" rules={[
                                {
                                    required: false,
                                    message: 'Twitter url is required',
                                },
                            ]} >
                                <Input placeholder="Twitter url" type="url" />
                            </Form.Item>
                        </Col>
                        <Col span={24} >
                            <Form.Item name='twitter_summary' label="Twitter Summary" rules={[
                                {
                                    required: false,
                                    message: 'Twitter summary is required',
                                },
                            ]} >
                                <Input placeholder="Twitter summary" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Space>
                            <Button type="primary" htmlType="submit">Submit</Button>
                            <Button onClick={() => history.goBack()} type="primary">Cancel</Button>
                        </Space>
                    </Row>
                </Col>
                <Col span={3}></Col>

            </Row >
        </Form >
    );
};


export default ProductForm