import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom"
import { Button, Col, Input, Row, Space, Form, Select, notification, message } from "antd"
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import ImageUpload from "../../components/image-upload";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"
import { CKEditor } from "@ckeditor/ckeditor5-react"
import ajaxService from "../../services/ajax-service";

const { Option } = Select

const options = ['Publish', 'Unpublish', 'Draft']

function BlogForm() {

    const [form] = Form.useForm();
    let history = useHistory();
    let { id } = useParams()

    const [text, setText] = useState("")
    const [fileList, setFileList] = useState([]);

    const handleImageUpload = (info) => {
        let fileList = [...info.fileList];
        // Limit file list to 1 item
        fileList = fileList.slice(-1);
        // Update file list state
        setFileList(fileList);
    };

    const onFinish = async (values) => {

        let link = '';

        let { image } = values;

        if (typeof image === "string") {
            link = image;
        } else {
            if (image !== undefined) {

                let { response } = image.file;

                if (response) {
                    let { success, path } = response;

                    if (success) {
                        link = path
                    }
                }
            }
        }

        let data = {
            blog: text,
            title: values.title,
            meta_title: values.meta_title,
            meta_description: values.meta_description,
            status: values.status,
            image: link,
        }

        if (text === '') {
            return message.error('Blog content is required')
        } else {
            if (id == 0) {
                const saveData = await ajaxService.post('blogs', data)

                if (saveData) {
                    history.push({ pathname: "/blogs" });
                    notification.open({ message: 'Blog has been saved', icon: <CheckCircleOutlined style={{ color: '#108ee9' }} /> })
                } else {
                    notification.open({ message: 'Unable to add blog', icon: <CloseCircleOutlined style={{ color: 'red' }} /> })
                }
            }
            else {
                const updateData = await ajaxService.put('blogs/'+id, data);
                if (updateData) {
                    history.push({ pathname: '/blogs' });
                    notification.open({ message: 'Blog has been updated', icon: <CheckCircleOutlined style={{ color: '#108ee9' }} /> })
                } else {
                    notification.open({ message: 'Unable to update blog', icon: <CloseCircleOutlined style={{ color: 'red' }} /> })
                }
            }
        }

    }

    const onFinishFailed = errorInfo => {
        console.log('Failed:', errorInfo);
    };


    const loadData = async (id) => {
        if (id > 0) {
            let response = await ajaxService.get('blogs/' + id);
            if (response.status == 200) {

                let { payload } = response.data;

                form.setFieldsValue({ ...payload });

                let image = {
                    uid: payload.image,
                    name: 'image.png',
                    status: 'done',
                    url: payload.image,
                    preview: false
                }

                setFileList([image]);
                                
                if (payload.blog != null) {
                    setText(payload.blog)
                }
            }
        }
    }

    useEffect(() => {
        loadData(id)
    }, [])

    return (
        <>
            <Form
                layout={'vertical'}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                form={form}>
                <Row gutter={16} >
                    <Col span={6}></Col>
                    <Col span={12} >
                        <Row gutter={16}>

                            <Col span={12}>
                                <Form.Item label="Title" name='title' rules={[
                                    {
                                        required: true,
                                        message: 'Title is required',
                                    }]}>
                                    <Input placeholder="Enter Title" />
                                </Form.Item>
                            </Col>

                            <Col span={12}>
                                <Form.Item label="Meta Title" name='meta_title'>
                                    <Input placeholder="Enter Meta Title" />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="Status" name='status'
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Status is required'
                                        }
                                    ]}
                                >
                                    <Select placeholder="Select Status">
                                        {
                                            options.map((option, i) => (
                                                <Option key={i} value={option}>{option}</Option>
                                            ))
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>

                            <Col span={12}>
                                <Form.Item label="Meta Decription" name='meta_description'>
                                    <Input placeholder="Enter Meta Description" />
                                </Form.Item>
                            </Col>


                            <Col span={24}>
                                <Form.Item label="Blog" >
                                    <CKEditor
                                        className='editor'
                                        editor={ClassicEditor}
                                        // onReady={(event, editor) => {
                                        //     console.log(editor)
                                        //     editor.setData(text)
                                        // }}

                                        data={text}
                                        
                                        onChange={(event, editor) => {
                                            const data = editor.getData()
                                            setText(data)
                                        }}
                                    />
                                </Form.Item>
                            </Col>

                            <Col span={12}>
                                <Form.Item label="Image" name="image">
                                    <ImageUpload onChange={handleImageUpload} fileList={fileList} accept="image/*" />
                                </Form.Item>
                            </Col>

                        </Row>
                        <Row>
                            <Form.Item>
                                <Space>
                                    <Button htmlType="submit" type="primary">Submit</Button>
                                    <Button onClick={() => history.goBack()} type="primary">Cancel</Button>
                                </Space>
                            </Form.Item>
                        </Row>
                    </Col>
                    <Col span={6}></Col>
                </Row >
            </Form >
        </>
    )
}

export default BlogForm