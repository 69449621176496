import React, { useState, useEffect } from 'react';
import { Table, Space, Button, Typography, notification, Row, Col, Avatar, Modal } from 'antd';
import { useHistory } from 'react-router-dom'
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import ajaxService from '../../../services/ajax-service';
import { DeleteOutlined, CloseCircleOutlined, MenuOutlined, CheckCircleOutlined } from '@ant-design/icons';
import arrayMove from 'array-move';

const { Title, Text } = Typography;

const DragHandle = SortableHandle(() => <MenuOutlined style={{ cursor: 'grab', color: '#999' }} />);
const SortableItem = SortableElement(props => <tr {...props} />);
const SortableBody = SortableContainer(props => <tbody {...props} />);

export default function Category() {

  const [dataSource, setDataSource] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const history = useHistory();

  const columns = [
    {
      title: 'Sort',
      dataIndex: 'sort',
      width: 30,
      className: 'drag-visible',
      render: () => <DragHandle />,
    },

    {
      title: 'Image',
      dataIndex: 'image',
      key: 'image',
      className: 'drag-visible',
      render: (image) => <Avatar shape="square" size={64} src={image} />

    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      className: 'drag-visible',
    },
    {
      title: 'Parent',
      dataIndex: 'parent_id',
      key: 'parent_id',
      render: (active) => <Text >{dataSource.map((v) => v.id == active && v.name)}</Text>,

    },
    {
      title: 'Active',
      dataIndex: 'active',
      render: (active) => <Text >{active == 1 ? 'Yes' : 'No'}</Text>,
      key: 'active',
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      width: 100,
      render: (text, record) => (
        <Space size='small'>
          <Button onClick={() => history.push({ pathname: "/categories/form/" + record.id })}  >Edit</Button>
          <Button onClick={() => openDeleteModal(record.id)}>Delete</Button>
        </Space>
      ),
    },
  ];

  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      const newData = arrayMove([].concat(dataSource), oldIndex, newIndex).filter(el => !!el);
      setDataSource(newData)
      
      const categoryId = dataSource[oldIndex].id
      setSortItem(newIndex, categoryId)
    }
  };

  const setSortItem = async (id, sort_order) => {
    let data = { category_id : id, sort_order }
    const response = await ajaxService.post('categories/sort', data);
    if (response.status === 200) {
      notification.open({ message: 'Sort Order Updated...', icon: <CheckCircleOutlined style={{ color: '#108ee9' }} /> })
    } else {
      notification.open({ message: 'Sort Order Error...', icon: <CloseCircleOutlined style={{ color: 'red' }} /> })

    }
  }

  const DraggableContainer = props => (
    <SortableBody
      useDragHandle
      disableAutoscroll
      helperClass="row-dragging"
      onSortEnd={onSortEnd}
      {...props}
    />
  );

  const DraggableBodyRow = ({ className, style, ...restProps }) => {
    // function findIndex base on Table rowKey props and should always be a right array index
    const index = dataSource.findIndex(x => x.id === restProps['data-row-key']);
    return <SortableItem index={index} {...restProps} />;
  };


  const initialize = async () => {
    const response = await ajaxService.get('categories');
    if (response !== undefined) {

      const { status, data } = response;
      
      if (status === 200) {
        setDataSource(data);
      }
    }
  }

  useEffect(() => {
    initialize();
  }, [])


  const renderHeader = () => {
    return (
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Title level={3}>Categories</Title>
        </div>
        <Space size="small" >
          <Button type="primary" onClick={() => history.push({ pathname: "/categories/form/0" })}  >Add Category</Button>
        </Space>
      </div>
    );
  }
  const deleteCategory = async () => {

    let response = await ajaxService.delete('categories/' + localStorage.getItem('category'));
    setShowModal(false)
    if (response.status === 200) {
      initialize();
      notification.open({ message: 'Category Deleted Successfully...', icon: <DeleteOutlined style={{ color: '#108ee9' }} /> })
    } else {
      notification.open({ message: 'Category Not Deleted...', icon: <CloseCircleOutlined style={{ color: 'red' }} /> })

    }
  }
  const openDeleteModal = (id) => {
    localStorage.setItem('category', id);
    setShowModal(true)
  }
  return (
    <div>

      <Row>
        <Col span={24}>
          <Table
            dataSource={dataSource}
            columns={columns}
            title={() => renderHeader()}
            rowKey="id"
            components={{
              body: {
                wrapper: DraggableContainer,
                row: DraggableBodyRow,
              },
            }}
          />
        </Col>
      </Row>
      <Modal
        title="Delete Category"
        centered
        visible={showModal}
        onCancel={() => setShowModal(false)}
        footer={[
          <Button key="delete" onClick={() => deleteCategory()}>
            Delete
          </Button>
          ,
          <Button key="back" onClick={() => setShowModal(false)}>
            Close
          </Button>
        ]}
      >
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <p>Are you sure you want to delete this category?</p>
        </div>
      </Modal>
    </div>
  )
}