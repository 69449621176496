import React from 'react';

import { Switch, BrowserRouter as Router, Route, Redirect } from "react-router-dom";

import AppRoute from './approute';

import AuthLayout from '../layout/auth';
import MainLayout from '../layout';
import Dashboard from '../pages/dashboard';
import Order from '../pages/order';
import OrderForm from '../pages/order/order';
import Store from '../pages/store';
import Category from '../pages/catalogue/category';
import Brand from '../pages/catalogue/brand';
import BrandForm from '../pages/catalogue/brand/form';
import Blogs from '../pages/blogs';
import BlogForm from '../pages/blogs/form';
import Offer from '../pages/offer';
import CategoryForm from '../pages/catalogue/category/form'
import ApplicationManager from '../pages/application';
import Settings from '../pages/settings';
import Sales from '../pages/reports/sales';
import Stock from '../pages/reports/stock';
import OfferForm from '../pages/offer/form'
import EloquentModel from '../pages/eloquent/model';
import Prunable from '../pages/eloquent/prunable';

import Product from '../pages/catalogue/product';
import ProductForm from '../pages/catalogue/product/form';
import ProductVariant from '../pages/catalogue/product/variant';
import Login from '../pages/auth/login';


const AuthRedirect = ({ destination }) => {
    return <Route render={(props) => (<Redirect to={{ pathname: destination, state: { from: props.location } }} />)} />;
}

const wrapper = (Layout, Component) => {

    let accessToken = localStorage.getItem('token');

    const redirect = <AuthRedirect destination={'/login'}></AuthRedirect>

    if (accessToken == null) return redirect;

    return (
        <Layout>
            <Component />
        </Layout>
    )
}

export default function Navigation() {
    return (
        <Router>
            <Route exact path={`/login`} component={() => <AuthLayout><Login /></AuthLayout>} />
            <Route exact path={`/setting`} component={() => wrapper(MainLayout,Settings)} />
            <Route exact path={`/offer/form/:id?`} component={() => wrapper(MainLayout,OfferForm)} />
            <Route exact path={`/offer`} component={() => wrapper(MainLayout,Offer)} />

            <Route exact path="/products/form/:id?" component={() => wrapper(MainLayout,ProductForm)} />
            <Route exact path="/products/variant/:id?" component={() => wrapper(MainLayout,ProductVariant)} />
            <Route exact path="/products" component={() => wrapper(MainLayout,Product)} />
            <Route exact path="/categories/form/:id?" component={() => wrapper(MainLayout,CategoryForm)} />
            <Route exact path="/categories" component={() => wrapper(MainLayout,Category)} />
            <Route exact path="/brands/form/:id?" component={() => wrapper(MainLayout,BrandForm)} />
            <Route exact path="/brands" component={() => wrapper(MainLayout,Brand)} />
            <Route exact path={`/blogs`} component={() => wrapper(MainLayout, Blogs)} />
            <Route exact path={`/blogs/form/:id?`} component={() => wrapper(MainLayout, BlogForm)} />            
            <Route exact path={`/orders/form/:id?`} component={() => wrapper(MainLayout,OrderForm)} />
            <Route exact path={`/`} component={() => wrapper(MainLayout,Order)} />
            {/* <Route exact path={`/`} component={() => wrapper(MainLayout,Dashboard)} /> */}
        </Router>
    );
}