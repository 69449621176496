import React, { useState, useEffect, useCallback } from "react";
import {
  Col,
  notification,
  Typography,
  Row,
  Divider,
  Avatar,
  Table,
  Button,
  Dropdown,
  Menu,
  message,
} from "antd";
import { PrinterOutlined } from "@ant-design/icons";
import { useParams } from "react-router-dom";
import ajaxService from "../../services/ajax-service";
import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { useWindowDimensions } from "../../hooks";
const { Text, Title } = Typography;

const OrderForm = () => {
  const [order, setOrder] = useState({});
  const [dataSource, setDataSource] = useState([]);
  const { width } = useWindowDimensions();
  const isMobile = width <= 768;

  let { id } = useParams();

  const columns = [
    {
      title: "Item",
      dataIndex: "product_name",
      key: "item",
    },
    {
      title: "Price",
      dataIndex: "total",
      key: "price",
      align: "right",
      render: (text) => <Text>AUD {Number(text)?.toFixed(2)}</Text>,
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
      align: "right",
    },
    {
      title: "Total",
      dataIndex: "total",
      key: "total",
      align: "right",
      render: (text) => <Text>AUD {Number(text)?.toFixed(2)}</Text>,
    },
  ];
  const changeStatus = async (status) => {
    let data = {
      id,
      status,
    };
    const response = await ajaxService.put("status/" + id, data);
    if (response.status === 200) {
      notification.open({
        message: "Order Status Updated...",
        icon: <CheckCircleOutlined style={{ color: "#108ee9" }} />,
      });
      window.location.reload();
    } else {
      notification.open({
        message: "Order Status Error...",
        icon: <CloseCircleOutlined style={{ color: "red" }} />,
      });
    }
  };
  const loadData = useCallback(async (id) => {
    console.log(id);
    if (id > 0) {
      let response = await ajaxService.get("orders/" + id);
      let { orderItem, order } = response.data;
      if (response.data != null) {
        setOrder(order);
        setDataSource(orderItem);
      }
    }
  }, []);

  useEffect(() => {
    loadData(id);
  }, []);

  return (
    <div style={{ background: "#fff", padding: "20px" }}>
      <Title level={3}>Order: #{order.order_number}</Title>

      <Row gutter={[16, 16]}>
        <Col md={12} xs={24}>
          <Title level={4}>Order Details</Title>
          <div className="flexItem">
            <Text>Order Number</Text>
            <Text>{order.order_number}</Text>
          </div>
          <div className="flexItem">
            <Text>Order Date</Text>
            <Text>{order.date}</Text>
          </div>
          <div className="flexItem">
            <Text>Order status</Text>
            <Dropdown
              overlay={
                <Menu>
                  <Menu.Item onClick={() => changeStatus("Confirm")} key="1">
                    Confirmed
                  </Menu.Item>
                  <Menu.Item onClick={() => changeStatus("Processing")} key="2">
                    Processing
                  </Menu.Item>
                  <Menu.Item
                    onClick={() => changeStatus("  On The Way")}
                    key="3"
                  >
                    On The Way
                  </Menu.Item>
                  <Menu.Item onClick={() => changeStatus("Complete")} key="4">
                    Complete
                  </Menu.Item>
                </Menu>
              }
              placement="bottomCenter"
            >
              <Button value={order.status}>{order.status}</Button>
            </Dropdown>
          </div>
          <div className="flexItem">
            <Text>Shipping Address</Text>
            <Text>{order.shipping_address}</Text>
          </div>
          <div className="flexItem">
            <Title level={4}>Payment Method</Title>
            <Text>{order.payment_method}</Text>
          </div>
        </Col>
        <Col md={12} xs={24}>
          <Title level={4}>Customer Information</Title>
          <div className="flexItem">
            <Text>Order Name</Text>
            <Text>{order.name}</Text>
          </div>
          <div className="flexItem">
            <Text>Email</Text>
            <Text>{order.email}</Text>
          </div>
          <div className="flexItem">
            <Text>Phone</Text>
            <Text>{order.phone}</Text>
          </div>
        </Col>
      </Row>
      <Row>
        <Title level={3}>Invoice Discription</Title>
        <Divider className="divide" />
      </Row>
      <Table pagination={false} dataSource={dataSource} columns={columns} />
      <div className="flexItem">
        <Text>SubTotal</Text>
        <Text>AUD {Number(order.subtotal)?.toFixed(2)}</Text>
      </div>
      <div className="flex">
        <Text>Shipping Charges</Text>
        <Text> AUD {Number(order.shipping)?.toFixed(2)}</Text>
      </div>
      <div className="flexItem">
        <Text>Total</Text>
        <Text>AUD {Number(order.total)?.toFixed(2)}</Text>
      </div>
    </div>
  );
};

export default OrderForm;
