import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
  Form,
  Input,
  Button,
  Checkbox,
  Card,
  Row,
  Col,
  Typography,
  Space,
  List,
  Comment,
} from "antd";
import {
  FacebookOutlined,
  TwitterOutlined,
  GoogleOutlined,
  InstagramOutlined,
} from "@ant-design/icons";
import ajaxService from "../../services/ajax-service";
const { Title, Paragraph } = Typography;

const data = [
  {
    content: (
      <Button
        type="primary"
        shape="round"
        icon={<FacebookOutlined />}
        size={"large"}
        style={{ width: "250px" }}
      >
        <md> Sign in with facebook</md>
      </Button>
    ),
  },
  {
    content: (
      <Button
        type="primary"
        shape="round"
        icon={<TwitterOutlined />}
        size={"large"}
        style={{ width: "250px" }}
      >
        <md>Sign in with twitter</md>
      </Button>
    ),
  },
  {
    content: (
      <Button
        type="primary"
        shape="round"
        icon={<GoogleOutlined />}
        size={"large"}
        style={{ width: "250px" }}
      >
        <md>Sign in with google</md>
      </Button>
    ),
  },
  {
    content: (
      <Button
        type="primary"
        shape="round"
        icon={<InstagramOutlined />}
        size={"large"}
        style={{ width: "250px" }}
      >
        <md>Sign in with instagram</md>
      </Button>
    ),
  },
];

const Login = () => {
  let history = useHistory();
  let location = useLocation();
  let { from } = location.state || { from: { pathname: "/dashboard" } };

  const onFinish = async (values) => {
    const response = await ajaxService.getAccessToken(
      values.username,
      values.password
    );

    if (response.status === 200) {
      localStorage.setItem("token", response.data.access_token);
      history.replace(from);
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <Row
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginTop: "70px",
        marginBottom: "70px",
      }}
    >
      <Col md={3}></Col>
      <Col md={18} xs={24}>
        <Row>
          <Col md={8}></Col>
          <Col md={8} xs={24}>
            <Card
              title="Sign in to your account"
              bordered={false}
              style={{ width: "100%" }}
            >
              <Form
                //{...layout}
                layout={"vertical"}
                name="basic"
                initialValues={{
                  remember: true,
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
              >
                <Form.Item
                  label="Username"
                  name="username"
                  rules={[
                    {
                      required: true,
                      message: "Username is required",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  label="Password"
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: "Password is required",
                    },
                  ]}
                >
                  <Input.Password />
                </Form.Item>

                <Form.Item name="remember" valuePropName="checked">
                  <Checkbox>Keep me signed in</Checkbox>
                </Form.Item>

                <Form.Item>
                  <Button type="primary" htmlType="submit">
                    Submit
                  </Button>
                </Form.Item>
                <md
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <a>Forgot Password</a>
                  <a>Create Account</a>
                </md>
              </Form>
            </Card>
          </Col>
          <Col md={8}></Col>
        </Row>
      </Col>
      <Col md={3}></Col>
    </Row>
  );
};

export default Login;
