import React, { useEffect, useState } from "react";
import { Avatar, Button, Modal, notification, Space, Table, Typography } from "antd";
import { CloseCircleOutlined, DeleteOutlined } from '@ant-design/icons';
import { useHistory } from "react-router-dom";
import { PAGINATION_OPTIONS } from "../../constants";
import ajaxService from "../../services/ajax-service";

const { Title } = Typography;

export default function Blogs() {

    let history = useHistory()
    const [dataSource, setDataSource] = useState([])
    const [showModal, setShowModal] = useState(false)
    const [forceRefresh, setForceRefresh] = useState(true);

    const columns = [
        {
            title: 'Image',
            dataIndex: 'image',
            key: 'image',
            render: (image) => <Avatar shape="square" size={64} src={image} />
        },
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title',
        },
        // {
        //     title: 'Blog',
        //     dataIndex: 'blog',
        //     key: 'blog',
        //     width: '50%',
        //     render: (blog) => <div style={{ width: '800px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }} dangerouslySetInnerHTML={{ __html: blog }} />
        // },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
        },
        {
            title: 'Actions',
            dataIndex: 'actions',
            key: 'actions',
            render: (text, record) => {
                return (
                    <Space size='small'>
                        <Button onClick={() => history.push({ pathname: '/blogs/form/' + record.id })}>Edit</Button>
                        <Button onClick={() => openDeleteModal(record.id)}>Delete</Button>
                    </Space>)
            }
        },
    ]

    const deleteRow = async () => {
        let response = await ajaxService.delete('blogs/' + localStorage.getItem('blog'));

        if (response !== undefined) {

            if (response.status === 200) {
                setShowModal(false)
                setForceRefresh(true)
                notification.open({ message: 'Blog has been deleted', icon: <DeleteOutlined style={{ color: '#108ee9' }} /> })
            }else{
                notification.open({ message: 'Unable to deleted blog', icon: <CloseCircleOutlined style={{ color: 'red' }} /> })
                
            }
        }else{
            notification.open({ message: 'Unable to deleted blog', icon: <CloseCircleOutlined style={{ color: 'red' }} /> })
        }
    }

    const openDeleteModal = (id) => {
        localStorage.setItem('blog', id);
        setShowModal(true)
    }

    const initialize = async () => {
        const response = await ajaxService.get('blogs');

        if (response !== undefined) {

            if (response.status === 200 && response.data.payload != null) {
                setDataSource(response.data.payload);
                setForceRefresh(false);
            }
        }
    }

    useEffect(() => {
        if (forceRefresh) {
            initialize();
        }
    }, [forceRefresh])

    const renderHeader = () => (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Title level={3}>Blogs</Title>
            <Space size="small" >
                <Button type="primary" onClick={() => history.push({ pathname: '/blogs/form/0' })}>Add Blog</Button>
            </Space>
        </div>
    )

    return (
        <>
            <Table
                dataSource={dataSource}
                columns={columns}
                title={() => renderHeader()}
                pagination={{ ...PAGINATION_OPTIONS }}
            />
            <Modal
                title="Delete Blog"
                centered
                visible={showModal}
                onCancel={() => setShowModal(false)}
                footer={[
                    <Button key="delete" onClick={() => deleteRow()}>
                        Delete
                    </Button>,
                    <Button key="back" onClick={() => setShowModal(false)}>
                        Close
                    </Button>
                ]}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <p>Are you sure you want to delete this blog?</p>
                </div>
            </Modal>
        </>
    )
}  