import React, { useState, useEffect } from 'react';
import { Form, Input, Button, notification, Row, Col, Checkbox, Space } from 'antd';
import ajaxService from '../../../services/ajax-service';
import { useHistory, useParams } from "react-router-dom";
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import ImageUpload from '../../../components/image-upload';
const { TextArea } = Input;

const BrandForm = () => {
    const [fileList, setFileList] = useState([]);
    let history = useHistory();
    let { id } = useParams();
    const [form] = Form.useForm();

    const onChange = ({ fileList: newFileList }) => {
        setFileList(newFileList);

        if (newFileList[0] !== undefined && newFileList[0].response !== undefined) {
            const { valid, payload } = newFileList[0].response;

            if (valid) {
                form.setFieldsValue({
                    image: payload
                });
            }
        }
    };

    const onFinish = async (values) => {
        let link = '';

        let { file } = values;

        if (file !== undefined) {

            let { response } = file.file;

            if (response) {
                let { success, path } = response;

                if (success) {
                    link = path
                }
            }
        }

        let data = {
            active: values.active,
            description: values.description,
            image: link,
            meta_description: values.meta_description,
            meta_title: values.meta_title,
            name: values.name
        }
        console.log(data)
        if (id == 0) {
            const response = await ajaxService.post('brands', data);

            if (response.status === 200) {
                history.push({ pathname: "/brands" });
                notification.open({ message: 'Brand Added Successfully...', icon: <CheckCircleOutlined style={{ color: '#108ee9' }} /> })
            } else {
                notification.open({ message: 'Brand Not Added...', icon: <CloseCircleOutlined style={{ color: 'red' }} /> })

            }

        } else {
            if (data.image === '') {
                data.image = fileList[0].url;
                data.active = values.active
            }
            const response = await ajaxService.put('brands/' + id, data);
            if (response.status === 200) {
                history.push({ pathname: "/brands" });
                notification.open({ message: 'Brand  Updated Successfully...', icon: <CheckCircleOutlined style={{ color: '#108ee9' }} /> })
            } else {
                notification.open({ message: 'Brand Not Updated ', icon: <CloseCircleOutlined style={{ color: 'red' }} /> })

            }

        }

    };

    const onFinishFailed = errorInfo => {
        console.log('Failed:', errorInfo);
    };
    useEffect(() => {

        const loadData = async (id) => {
            if (id > 0) {
                let response = await ajaxService.get('brands/' + id);
                if (response.data != null) {
                    form.setFieldsValue({ ...response.data });

                    let imageItem = {
                        uid: response.data.image,
                        name: 'image.png',
                        status: 'done',
                        url: response.data.image,
                        preview: false
                    }
                    setFileList([imageItem]);
                }
            }
        }
        loadData(id);
    }, []);


    return (
        <Form
            layout={'vertical'}
            initialValues={{
                active: true,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            form={form}
        >
            <Row gutter={16} >
                <Col span={6}></Col>
                <Col span={12} >
                    <Row gutter={16}> 
                        <Col span={12}>
                            <Form.Item label="Name" name='name' rules={[
                                {
                                    required: true,
                                    message: 'Brand Name is required',
                                },
                            ]}>
                                <Input placeholder="brand name" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item name='meta_title' label="Meta Title" rules={[
                                {
                                    required: true,
                                    message: 'Meta Title is required',
                                },
                            ]}>
                                <Input placeholder="meta title" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item name='meta_description' label="Meta Description" rules={[
                                {
                                    required: true,
                                    message: 'Meta Description is required',
                                },
                            ]}>
                                <Input placeholder="meta Description" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item name="active" valuePropName="checked">
                                <Checkbox>Active</Checkbox>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row >
                        <Col span={24}>
                            <Form.Item label="Upload Image" name="file" rules={[
                                {
                                    required: false,
                                    message: 'Image is required',
                                },
                            ]}>
                                <ImageUpload onChange={onChange} fileList={fileList} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Form.Item>
                            <Space>
                                <Button htmlType="submit" type="primary">Submit</Button>
                                <Button onClick={() => history.goBack()} type="primary">Cancel</Button>
                            </Space>
                        </Form.Item>
                    </Row>

                </Col>

                <Col span={6}></Col>
            </Row>
        </Form>
    );
};


export default BrandForm