
import React, { useRef, useState, useCallback, useEffect } from "react";
import AntdInputTag from "antd-input-tag";
import { Form, Input, InputNumber, Button, notification, Row, Col, Checkbox, Select, Typography, Divider, Space, message } from 'antd';
import { PlusOutlined, CheckCircleOutlined, DeleteOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { useHistory, useParams } from "react-router-dom";
import ajaxService from "../../../services/ajax-service";
import ImageUpload from "../../../components/image-upload";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"
import { CKEditor } from "@ckeditor/ckeditor5-react"
const { Option } = Select
const { Title } = Typography;
const { TextArea } = Input
const Variant = () => {
    const [categories, setcategories] = useState([])
    const [brands, setBrands] = useState([])
    const [attribute, setAttribute] = useState({ first: '', second: '', third: '' });
    const [fileList, setFileList] = useState([]);
    const [imagesList, setImagesList] = useState([]);
    const [text, setText] = useState("")
    const childRef1 = useRef();
    const childRef2 = useRef();
    const childRef3 = useRef();
    const [form] = Form.useForm();
    let history = useHistory();
    let { id } = useParams();

    let initialValues = {
        variants: [],
        image: []
    };


    const handleVariantImg = ({ fileList: newFileList }) => {
        setFileList(newFileList);
        console.log(fileList)
        if (newFileList[0] !== undefined && newFileList[0].response !== undefined) {
            console.log(newFileList)
            const image = newFileList[0].response.path;
            form.setFieldsValue({ image });
        }
    };
    const generateVariants = () => {
        const arr = []

        if (childRef1.current.changeVal().length > 0) {
            childRef1.current.changeVal().map((v1) => {
                if (childRef2.current.changeVal().length) {
                    childRef2.current.changeVal().map((v2) => {

                        if (childRef3.current.changeVal().length > 0) {
                            childRef3.current.changeVal().map((v3) => {
                                arr.push([v1, v2, v3]);
                            })
                        } else {
                            arr.push([v1, v2]);
                        }
                    })
                } else {
                    arr.push([v1]);
                }
            })
        }


        if (arr.length > 0) {

            let variants = [];

            arr.map(variant => {
                variants.push(
                    {
                        attribute1: attribute.first, attribute1Value: variant[0],
                        attribute2: attribute.second, attribute2Value: variant[1],
                        attribute3: attribute.third, attribute3Value: variant[2],
                        id: 0
                    })

            })

            console.log(variants)

            form.setFieldsValue({ variants });
        }
    }

    const onChange = ({ fileList: newFileList }) => {
        setFileList(newFileList);
        let list = []

        fileList.map((item) => {
            if (item.response !== undefined) {
                list.push({
                    uid: item.response.path,
                    name: 'image.png',
                    status: 'done',
                    url: item.response.path,
                    preview: false
                })
            }
        })
        setImagesList(list)
    };
    const onFinish = async (values) => {

        if (values.variants.length > 0) {

            let imageData = []

            fileList.map((item) => {

                if (item.response != null && item.response.path !== '') {
                    imageData.push(item.response.path)
                }
            })

            const variant_group = new Date().getTime();

            let counter = 0;
            let isAdd = false;

            values.variants.map((variant, index) => {

                const variantdata = {
                    brand_id: 0,//values.brand_id,
                    category_id: 0,//values.category_id,
                    description: text,
                    image: imageData,
                    meta_description: values.meta_description,
                    meta_title: values.meta_title,
                    name: values.name,
                    attribute1: variant.attribute1,
                    attribute1_value: variant.attribute1Value,
                    attribute2: variant.attribute2,
                    attribute2_value: variant.attribute2Value,
                    attribute3: variant.attribute3,
                    attribute3_value: variant.attribute3Value,
                    breath: variant.breath,
                    cost_price: variant.cost_price,
                    heigth: variant.heigth,
                    length: variant.length,
                    selling_price: variant.selling_price,
                    sku: variant.sku,
                    stock: variant.stock,
                    unlimited_stock: variant.unlimited_stock,
                    short_description: values.short_description,
                    weight: variant.weight,
                    active: variant.active,
                    has_variant: true,
                    offer: false,
                    og_title : values.og_title,
                    og_description : values.og_description,
                    og_url : values.og_url,
                    twitter_title : values.twitter_title,
                    twitter_description : values.twitter_description,
                    twitter_url : values.twitter_url,
                    twitter_summary : values.twitter_summary                          
                }

                if (variant.id > 0) {
                    variantdata.is_main = (index === 0);
                    variantdata.image = imageData;
                    variantdata.active = variant.active
                    variantdata.unlimited_stock = variant.unlimited_stock;

                    const response = ajaxService.put('products/' + variant.id, variantdata);
                    if (!response) {
                        counter++;
                    }
                } else {
                    isAdd = true;
                    variantdata.image = imageData;
                    variantdata.is_main = (index === 0);
                    variantdata.active = variant.active
                    variantdata.unlimited_stock = variant.unlimited_stock
                    variantdata.variant_group = variant_group;

                    const response = ajaxService.post('products', variantdata);
                    if (!response) {
                        counter++;
                    }
                }
            })

            if (counter > 0) {
                notification.open({ message: 'Unable to save one or more products. Please try again.', description: 'Error', icon: <CloseCircleOutlined style={{ color: 'red' }} /> })
            } else {
                history.push({ pathname: "/products" });
                if (isAdd) {
                    notification.open({ message: 'Product(s) added successfully...', icon: <CheckCircleOutlined style={{ color: '#108ee9' }} /> })
                } else {
                    notification.open({ message: 'Product(s) updated successfully...', icon: <CheckCircleOutlined style={{ color: '#108ee9' }} /> })
                }
            }
        } else {
            // console.log(values)
        }
    };

    const onFinishFailed = errorInfo => {
        // message.error('Form Not Completed. ')
    };

    const loadCategories = useCallback(async () => {
        const response = await ajaxService.get('categories');
        const { status, data } = response;

        if (status === 200) {
            setcategories(data);
        }
    }, [])
    const loadBrands = useCallback(async () => {
        const response = await ajaxService.get('brands');
        const { status, data } = response;

        if (status === 200) {
            setBrands(data);
        }
    }, [])

    const loadData = useCallback(async (id) => {
        if (id > 0) {

            let response = await ajaxService.get('products/' + id);
            if (response.data != null) {
                const variant = response.data[0]
                form.setFieldsValue({ ...variant });
                form.setFieldsValue({ category_id: parseInt(variant.category_id) })
                form.setFieldsValue({ brand_id: parseInt(variant.brand_id) })
                //setText(response.data[0].description)

                let variants = [];

                response.data.map(variant => {
                    variants.push(
                        {
                            active: variant.active,
                            attribute1: variant.attribute1,
                            attribute1Value: variant.attribute1_value,
                            attribute2: variant.attribute2,
                            attribute2Value: variant.attribute2_value,
                            attribute3: variant.attribute3,
                            attribute3Value: variant.attribute3_value,
                            breath: variant.breath,
                            cost_price: variant.cost_price,
                            file: variant.image,
                            heigth: variant.heigth,
                            length: variant.length,
                            selling_price: variant.selling_price,
                            sku: variant.sku,
                            stock: variant.stock,
                            unlimited_stock: variant.unlimited_stock,
                            weight: variant.weight,
                            id: variant.id
                        })

                })
                form.setFieldsValue({ variants });

                let images = []
                response.data[0].images.map((v) => {

                    let imageItem = {
                        uid: v.id,
                        name: 'image.png',
                        status: 'done',
                        url: v.url,
                        preview: false
                    }
                    images.push(imageItem)
                })


                setFileList(images);
            }
        }
    }, [])

    useEffect(() => {
        // console.log(id)
        loadCategories();
        loadBrands();
        loadData(id);
    }, []);


    return (
        <Form
            layout={'vertical'}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            initialValues={initialValues}
            form={form}
        >
            <Row gutter={16} >
                <Col span={3}></Col>
                <Col span={18} >
                    <Row gutter={16} >
                        <Col span={16}  >
                            <Form.Item name='name' label="Name" rules={[
                                {
                                    required: true,
                                    message: 'Product Name is required',
                                },
                            ]}>
                                <Input placeholder="product name" />
                            </Form.Item>

                            <Form.Item name='short_description' label="Short Description" rules={[
                                {
                                    required: true,
                                    message: 'Short Description is required',
                                },
                            ]}>
                                <TextArea rows={3} maxLength={300} placeholder="maxLength is 300" />
                            </Form.Item>

                            <Form.Item name='description' label="Description">
                                <div style={{ heigth: '200px' }}>
                                    <CKEditor

                                        className='editor'
                                        editor={ClassicEditor}
                                        data={text}
                                        onChange={(event, editor) => {
                                            const data = editor.getData()
                                            setText(data)
                                        }}
                                    />
                                </div>
                            </Form.Item>

                        </Col>

                        <Col span={8} >
                            <Form.Item name='meta_title' label="Meta Title" rules={[
                                {
                                    required: true,
                                    message: 'Meta title Name is required',
                                },
                            ]}>
                                <Input placeholder="meta title" />
                            </Form.Item>

                            <Form.Item name='meta_description' label="Meta description" rules={[
                                {
                                    required: true,
                                    message: 'Meta description Name is required',
                                },
                            ]}>
                                <Input placeholder="meta description" />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row >
                        <Col span={24}>
                            <Form.Item label="Upload Images" name="file">
                                <ImageUpload onChange={onChange} fileList={fileList} />
                            </Form.Item>
                        </Col>
                    </Row>
                    {id == 0 &&
                        <>
                            <Row >
                                <Col span={20}>
                                    <Title level={4}> Generate Variants By Entering Attribute And Their Values.</Title>
                                </Col>
                                <Col span={4}>
                                    <Button onClick={generateVariants} type="primary">Generate Variants</Button>
                                </Col>


                            </Row>

                            <Row gutter={16}>
                                <Col span={6}>
                                    <Form.Item label="First Attribute">
                                        <Input onChange={(e) => setAttribute({ ...attribute, first: e.target.value })} placeholder="" />
                                    </Form.Item>
                                </Col>
                                <Col span={18}>
                                    <Form.Item label="Values">
                                        <AntdInputTag onKeyDown={(e) => e.keyCode == 13 ? e.preventDefault() : ''} value={[]} ref={childRef1} />
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row gutter={16}>
                                <Col span={6}>
                                    <Form.Item label="Second Attribute">
                                        <Input onChange={(e) => setAttribute({ ...attribute, second: e.target.value })} placeholder="" />
                                    </Form.Item>
                                </Col>
                                <Col span={18}>
                                    <Form.Item label="Values">
                                        <AntdInputTag value={[]} ref={childRef2} />
                                    </Form.Item>
                                </Col>

                            </Row>
                            <Row gutter={16}>
                                <Col span={6}>
                                    <Form.Item label="Third Attribute">
                                        <Input onChange={(e) => setAttribute({ ...attribute, third: e.target.value })} placeholder="" />
                                    </Form.Item>
                                </Col>
                                <Col span={18}>
                                    <Form.Item label="Values">
                                        <AntdInputTag value={[]} ref={childRef3} />

                                    </Form.Item>
                                </Col>

                            </Row>
                        </>}
                    <Row gutter={[16, 10]}>
                        <Col span={8}>
                            Variant
                        </Col>
                        <Col span={4}>
                            SKU & Stock
                        </Col>
                        <Col span={4}>
                            Cost & Sale Price
                        </Col>
                        <Col span={4}>
                            weight & Length
                        </Col>
                        <Col span={4}>
                            breath & heigth
                        </Col>
                    </Row>

                    <Form.List name="variants">
                        {(fields, { add, remove }) => (
                            <>
                                {fields.map(({ key, name, ...restField }) => (
                                    <>
                                        <Space key={key} >
                                            <Row gutter={6}>
                                                <Col span={8} >
                                                    <Row gutter={7}>
                                                        <Col span={12}>
                                                            <Form.Item  {...restField} name={[name, 'id']} style={{ display: 'none' }} >
                                                                <Input type='hidden' />
                                                            </Form.Item>
                                                            <Form.Item  {...restField} name={[name, 'attribute1']} >
                                                                <Input placeholder="Attribute 1" />
                                                            </Form.Item>
                                                        </Col>
                                                        <Col span={12}>
                                                            <Form.Item {...restField} name={[name, 'attribute1Value']}>
                                                                <Input placeholder="Attribute 1 Value" />
                                                            </Form.Item>
                                                        </Col>
                                                    </Row>
                                                    <Row gutter={[6, 0]}>
                                                        <Col span={12}>
                                                            <Form.Item {...restField} name={[name, 'attribute2']} >
                                                                <Input placeholder="Attribute 2" />
                                                            </Form.Item>
                                                        </Col>
                                                        <Col span={12}>
                                                            <Form.Item {...restField} name={[name, 'attribute2Value']} >
                                                                <Input placeholder="Attribute 2 Value" />
                                                            </Form.Item>

                                                        </Col>
                                                    </Row>
                                                    <Row gutter={[6, 0]}>
                                                        <Col span={12}>
                                                            <Form.Item {...restField} name={[name, 'attribute3']} >
                                                                <Input placeholder="Attribute 3" />
                                                            </Form.Item>

                                                        </Col>
                                                        <Col span={12}>
                                                            <Form.Item {...restField} name={[name, 'attribute3Value']}  >
                                                                <Input placeholder="Attribute 3 Value" />
                                                            </Form.Item>

                                                        </Col>
                                                    </Row>
                                                </Col>

                                                <Col span={4}>
                                                    <Form.Item  {...restField} name={[name, 'sku']} rules={[
                                                        {
                                                            required: true,
                                                            message: 'SKU is required',
                                                        },
                                                    ]}  >
                                                        <Input placeholder="SKU" />
                                                    </Form.Item>
                                                    <Form.Item  {...restField} name={[name, 'stock']}>
                                                        <Input placeholder="Stock" />
                                                    </Form.Item>
                                                    <Form.Item  {...restField} name={[name, 'unlimited_stock']} valuePropName="checked">
                                                        <Checkbox>Unlimited Stock</Checkbox>
                                                    </Form.Item>
                                                </Col>
                                                <Col span={4}>
                                                    <Form.Item  {...restField} name={[name, 'cost_price']} >
                                                        <InputNumber placeholder="" step="0.01" />
                                                    </Form.Item>
                                                    <Form.Item  {...restField} name={[name, 'selling_price']} rules={[
                                                        {
                                                            required: true,
                                                            message: 'Selling Price is required',
                                                        },
                                                    ]} >
                                                        <InputNumber placeholder="" step="0.01" />
                                                    </Form.Item>
                                                    <Form.Item  {...restField} name={[name, 'active']} valuePropName="checked">
                                                        <Checkbox>Active</Checkbox>
                                                    </Form.Item>
                                                </Col>
                                                <Col span={4}>
                                                    <Form.Item  {...restField} name={[name, 'weight']}   >
                                                        <Input placeholder="weight" />
                                                    </Form.Item>
                                                    <Form.Item  {...restField} name={[name, 'length']} >
                                                        <Input placeholder="length" />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={4}>
                                                    <Form.Item  {...restField} name={[name, 'breath']}  >
                                                        <Input placeholder="breath" />
                                                    </Form.Item>

                                                    <Form.Item  {...restField} name={[name, 'heigth']} >
                                                        <Input placeholder="heigth" />
                                                    </Form.Item>
                                                    <Form.Item>
                                                        <Button type="dashed" onClick={() => remove(name)} block icon={<DeleteOutlined />}>
                                                            Remove
                                                        </Button>
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        </Space>
                                        <Divider plain />
                                    </>
                                ))}

                                <Form.Item>
                                    <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                        Add Variant
                                    </Button>
                                </Form.Item>
                            </>
                        )}
                    </Form.List>
                    <h2>SEO <small><em>(Optional)</em></small></h2>
                    <hr />
                    <Row gutter={16}>
                        <Col span={8} >
                            <Form.Item name='og_title' label="Open Graph Title" rules={[
                                {
                                    required: false,
                                    message: 'Open Graph Title is required',
                                },
                            ]} >
                                <Input placeholder="Facebook open graph title" />
                            </Form.Item>
                        </Col>
                        <Col span={8} >
                            <Form.Item name='og_description' label="Open Graph Description" rules={[
                                {
                                    required: false,
                                    message: 'Open Graph Description is required',
                                },
                            ]} >
                                <Input placeholder="Facebook open graph description" />
                            </Form.Item>
                        </Col>
                        <Col span={8} >
                            <Form.Item name='og_url' label="Open Graph Url" rules={[
                                {
                                    required: false,
                                    message: 'Open Graph Url is required',
                                },
                            ]} >
                                <Input placeholder="Facebook open graph url" type="url" />
                            </Form.Item>
                        </Col>

                        <Col span={8} >
                            <Form.Item name='twitter_title' label="Twitter Title" rules={[
                                {
                                    required: false,
                                    message: 'Twitter title is required',
                                },
                            ]} >
                                <Input placeholder="Twitter title" />
                            </Form.Item>
                        </Col>
                        <Col span={8} >
                            <Form.Item name='twitter_description' label="Twitter Description" rules={[
                                {
                                    required: false,
                                    message: 'Twitter description is required',
                                },
                            ]} >
                                <Input placeholder="Twitter description" />
                            </Form.Item>
                        </Col>
                        <Col span={8} >
                            <Form.Item name='twitter_url' label="Twitter Url" rules={[
                                {
                                    required: false,
                                    message: 'Twitter url is required',
                                },
                            ]} >
                                <Input placeholder="Twitter url" type="url" />
                            </Form.Item>
                        </Col>
                        <Col span={24} >
                            <Form.Item name='twitter_summary' label="Twitter Summary" rules={[
                                {
                                    required: false,
                                    message: 'Twitter summary is required',
                                },
                            ]} >
                                <Input placeholder="Twitter summary" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Form.Item >
                                <Space>
                                    <Button type="primary" htmlType="submit">Submit</Button>
                                    <Button onClick={() => history.goBack()} type="primary">Cancel</Button>
                                </Space>
                            </Form.Item>
                        </Col>
                    </Row>
                </Col>
                <Col span={3} ></Col>
            </Row >
        </Form >
    );
};


export default Variant