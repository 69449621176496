import React, { useState, useEffect } from 'react';
import { Table, Space, Button, Typography, Modal, notification } from 'antd';
import { useHistory } from 'react-router-dom';
import ajaxService from '../../services/ajax-service';
import { DeleteOutlined, CloseCircleOutlined } from '@ant-design/icons';

const { Title, Text } = Typography;


export default function Offer() {

    const [dataSource, setDataSource] = useState([])
    const [showModal, setShowModal] = useState(false);

    const history = useHistory();

  
    const columns = [

        // {
        //     title: 'ID',
        //     dataIndex: 'id',
        //     key: 'id',
        //     fixed: 'left',
        // },
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title',

        },

        {
            title: 'Start date',
            dataIndex: 'start_date',
            key: 'start_date',

        },
        {
            title: 'Start Time',
            dataIndex: 'start_time',
            key: 'start_time',

        },
        {
            title: 'End Date',
            dataIndex: 'end_date',
            key: 'end_date',
        },
        {
            title: 'End Time',
            dataIndex: 'end_time',
            key: 'end_time',

        },
        {
            title: 'Actions',
            dataIndex: 'actions',
            key: 'actions',
            fixed: 'right',
            width: 100,
            render: (text, record) => (
                <Space size='small'>
                    <Button onClick={() => history.push({ pathname: "/offer/form/" + record.id })}>Edit</Button>
                    <Button onClick={() => openDeleteModal(record.id)}>Delete</Button>
                </Space>
            ),
        },
    ];


    const initialize = async () => {
        const response = await ajaxService.get('offers');

        const { status, data } = response;

        if (status === 200) {
            setDataSource(data);
        }
    }
    const deleteOffer = async () => {

        let response = await ajaxService.delete('offers/' + localStorage.getItem('offer'));
        setShowModal(false)
        if (response.status === 200) {
            initialize();
            notification.open({ message: 'Offer Deleted Successfully...', icon: <DeleteOutlined style={{ color: '#108ee9' }} /> })
          } else {
            notification.open({ message: 'Offer Not Deleted...', icon: <CloseCircleOutlined style={{ color: 'red' }} /> })
      
          }
    }
    const openDeleteModal = (id) => {
        localStorage.setItem('offer', id);
        setShowModal(true)
    }


    useEffect(() => {
        initialize();
    }, [])

    return (
        <div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Title level={3}>Offers</Title>
                <Button type="primary" onClick={() => history.push({ pathname: "/offer/form/0" })} >Add Offer</Button>
            </div>
            <Table
                dataSource={dataSource}
                columns={columns}
            />
            <Modal
                title="Delete Product"
                centered
                visible={showModal}
                onCancel={() => setShowModal(false)}
                footer={[
                    <Button key="delete" onClick={() => deleteOffer()}>
                        Delete
                    </Button>
                    ,
                    <Button key="back" onClick={() => setShowModal(false)}>
                        Close
                    </Button>
                ]}
            >
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <p>Are you sure you want to delete this offer?</p>
                </div>
            </Modal>
        </div>
    )


}



