import React, { useState, useCallback, useEffect } from 'react';
import { Form, Input, Button, Checkbox, Row, Col, Table, Select, DatePicker, TimePicker, Space, Typography, Divider, notification } from 'antd';
import { useParams, useHistory } from "react-router-dom";
import ajaxService from '../../services/ajax-service';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';

import moment from 'moment';
const { Text } = Typography
const { Option } = Select


const OfferForm = () => {

    const [product, setProduct] = useState([])
    const [brands, setBrands] = useState([])
    const [categories, setCategories] = useState([]);
    const [allChange, setAllChange] = useState({ value: '1', type: '1' });
    const [tableData, setTableData] = useState([]);
    const [selection, setSelection] = useState({ categoryId: [], brandId: [], product_id: [] });
    const [checkedList, setCheckedList] = useState();
    // const [indeterminate, setIndeterminate] = useState(true);
    const [check, setCheck] = useState([]);

    const [form] = Form.useForm();
    let { id } = useParams()
    let history = useHistory()

    const handleAdd = () => {
        const selected_products = []
        const found_products = []

        product.map((v) => {
            console.log(selection.categoryId.includes(parseInt(v.category_id)))
            if (selection.categoryId.includes(parseInt(v.category_id))) {
                // console.log(v)
                selected_products.push(v.id)
            }

            if (selection.brandId.includes(parseInt(v.brand_id))) {
                selected_products.push(v.id)
            }

            if (selection.product_id.includes(v.id)) {
                selected_products.push(v.id)
            }

        })
        product.map((v) => {

            if (selected_products.includes(v.id)) {
                let row = {
                    product_id: v.id,
                    name: v.name,
                    selling_price: v.selling_price,
                    percentage: 0,
                    amount: 0,
                    discounted_price: 0,
                    type: 1,
                    value: ''
                }
                found_products.push(row)
            }
        })
        setTableData(found_products)
        if (found_products.length > 0) {
            notification.open({ message: 'Products Added Successfuly ', icon: <CheckCircleOutlined style={{ color: '#108ee9' }} /> })
        } else {
            notification.open({ message: 'Plz Add Offer Items', icon: <CloseCircleOutlined style={{ color: 'red' }} /> })
        }

    }
    const handleDelete = (product) => {
        console.log(product)
        let filter = []
        tableData.map((v) => {
            if (product.product_id !== v.product_id) {
                filter.push(v)
            }
        })
        setTableData(filter)
        // notification.open({message:'Product Successfuly Deleted'})
    }
    const handleAllDelete = () => {
        console.log(check)
        let filter = []
        if (checkedList === true) {
            setTableData('')
        } else {
            tableData.map((v, key) => {
                if (!check.includes(v.product_id)) {
                    filter.push(v)
                }

            })
            setTableData(filter)

        }
    }
    const handleTypeChange = (type, product_id) => {

        tableData.map(item => {
            if (item.product_id == product_id) {
                item.type = type;

                if (parseInt(item.type) == 1) {
                    item.amount = ((item.selling_price / 100) * item.value).toFixed("2")
                    item.percentage = item.value
                    item.discounted_price = item.selling_price - item.amount;
                } else {
                    item.percentage = ((item.value * 100) / item.selling_price).toFixed("2");
                    item.amount = item.value;
                    item.discounted_price = item.selling_price - item.value;
                }
            }

            return item;
        });

        setTableData([...tableData])
    }
    const handleChangeAll = () => {
        console.log(tableData)
        tableData.map(item => {


            if (parseInt(allChange.type) == 1) {
                item.amount = ((item.selling_price / 100) * allChange.value).toFixed("2")
                item.percentage = allChange.value
                item.discounted_price = item.selling_price - item.amount;
            } else {
                item.percentage = ((allChange.value * 100) / item.selling_price).toFixed("2");
                item.amount = allChange.value;
                item.value = allChange.value;
                item.discounted_price = item.selling_price - allChange.value;
            }

            return item;
        });

        setTableData([...tableData]);
    }
    const handleChange = (value, product_id) => {
        // if (id == 0) {
        tableData.map(item => {
            // console.log(item.type)
            if (item.product_id == product_id) {
                // console.log(item)
                if (parseInt(item.type) == 1) {
                    item.amount = ((item.selling_price / 100) * value).toFixed("2")
                    item.percentage = value
                    item.discounted_price = item.selling_price - item.amount;
                } else {
                    item.percentage = ((value * 100) / item.selling_price).toFixed("2");
                    item.amount = value;
                    item.value = value;
                    item.discounted_price = item.selling_price - value;
                }
            }
            return item;
        });

        setTableData([...tableData]);

    }

    const onFinish = async (values) => {
        let data = {
            title: values.title,
            start_date: values.start_date,
            start_time: values.start_time,
            end_date: values.end_date,
            end_time: values.end_time,
            items: tableData
        }
        if (id == 0) {
            const response = await ajaxService.post('offers', data);
            if (response.status === 200) {
                history.push({ pathname: "/offer" });
                notification.open({ message: 'Offer Added Successfully...', icon: <CheckCircleOutlined style={{ color: '#108ee9' }} /> })
            } else {
                notification.open({ message: 'Offer Not Added...', icon: <CloseCircleOutlined style={{ color: 'red' }} /> })
            }
        } else {
            const response = await ajaxService.put('offers/' + id, data);
            if (response.status === 200) {
                console.log(data)
                history.push({ pathname: "/offer" });
                notification.open({ message: 'Offer Updated Successfully...', icon: <CheckCircleOutlined style={{ color: '#108ee9' }} /> })
            } else {
                notification.open({ message: 'Offer Not Updated...', icon: <CloseCircleOutlined style={{ color: 'red' }} /> })
            }
        }
    };

    const handleCheck = (checked) => {
        setCheck(old => [...old, checked]);


    }
    const loadCategories = useCallback(async () => {
        const response = await ajaxService.get('categories');
        const { status, data } = response;
        if (status === 200) {
            setCategories(data);
        }
    }, [])
    const loadBrands = useCallback(async () => {
        const response = await ajaxService.get('brands');
        const { status, data } = response;
        if (status === 200) {
            setBrands(data);
        }
    }, [])
    const loadProducts = useCallback(async () => {
        const response = await ajaxService.get('products');
        const { status, data } = response;
        if (status === 200) {
            setProduct(data);
        }
    }, [])

    const loadData = useCallback(async (id) => {
        if (id > 0) {
            let response = await ajaxService.get('offers/' + id);
            if (response.data != null) {
                let dateTime = {
                    start_date: moment(response.data.start_date),
                    start_time: moment(response.data.start_time, 'HH:mm'),
                    end_date: moment(response.data.end_date),
                    end_time: moment(response.data.end_time, 'HH:mm'),
                    title: response.data.title
                }
                form.setFieldsValue({ ...dateTime });

                setTableData(response.data.items)
            }
        }
    }, [])

    useEffect(() => {
        loadData(id)
        loadCategories();
        loadBrands();
        loadProducts();
    }, []);



    return (
        <>
            <Form
                layout={'vertical'}
                initialValues={{
                    active: true,
                }}
                onFinish={onFinish}
                form={form}
            >
                <Row gutter={16} >
                    <Col span={3}></Col>
                    <Col span={18} className="gutter-row" >
                        <Row gutter={10}>
                            <Col span={8}>
                                <Form.Item name='title' label="Title" required>
                                    <Input placeholder='offer' />
                                </Form.Item>
                            </Col>
                            <Col span={4}>
                                <Form.Item name='start_date' label="Start Date" required>
                                    <DatePicker />
                                </Form.Item>

                            </Col>
                            <Col span={4}>
                                <Form.Item name='start_time' label="Start Time" required>
                                    <TimePicker />
                                </Form.Item>
                            </Col>
                            <Col span={4}>
                                <Form.Item name='end_date' label="End Date" required>
                                    <DatePicker />
                                </Form.Item>
                            </Col>
                            <Col span={4}>
                                <Form.Item name='end_time' label="End Time" required>
                                    <TimePicker />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Divider />
                        <Row gutter={10}>
                            <Col span={8}>
                                <Form.Item label="Category" >
                                    <Select
                                        mode="multiple"
                                        onChange={(e) => setSelection({ ...selection, categoryId: e })}
                                        placeholder="Click To Select Category"

                                    >
                                        {categories.map(order => {
                                            return <Option value={order.id} key={"order-" + order.id} >{order.name}</Option>
                                        })}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item label="Brand" >
                                    <Select
                                        mode="multiple"
                                        placeholder="Click To Select Brand"
                                        onChange={(e) => setSelection({ ...selection, brandId: e })}
                                        style={{ width: '100%' }}
                                    >
                                        {brands.map(brand => {
                                            return <Option value={brand.id} key={"brand-" + brand.id} >{brand.name}</Option>
                                        })}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item label="Product" >
                                    <Select
                                        mode="multiple"
                                        placeholder="Click To Select Product"

                                        onChange={(e) => setSelection({ ...selection, product_id: e })}
                                        style={{ width: '100%' }}
                                    >
                                        {product.map(product => {
                                            return <Option value={product.id} key={"product-" + product.id} >{product.name}</Option>
                                        })}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Form.Item>
                            <Button onClick={handleAdd} type="primary">Add product</Button>
                        </Form.Item>

                    </Col>
                    <Col span={3}></Col>
                </Row>
                <Divider />
                {tableData.length > 0 &&
                    <Row>
                        <Col span={3}></Col>
                        <Col span={18}>
                            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 10 }}>
                                <Button onClick={handleAllDelete} type="primary" >Delete</Button>
                                <Space>
                                    <Select defaultValue="1" onChange={(value) => setAllChange({ ...allChange, type: value })}>
                                        <Option value="1">Percentage</Option>
                                        <Option value="2">Amount</Option>
                                    </Select>
                                    <Input onChange={(v) => setAllChange({ ...allChange, value: v.target.value })} />
                                    <Button onClick={handleChangeAll} type="primary" >Apply</Button>
                                </Space>
                            </div>
                            <Divider />
                            <Row gutter={16} >
                                {/* <Col span={2}> <Checkbox onChange={(e) => setCheckedList(e.target.checked)} >All</Checkbox> </Col> */}
                                <Col span={2}> <Checkbox onChange={(e) => setCheckedList(e.target.checked)} >All</Checkbox> </Col>
                                <Col span={4}> <Text>Product Name</Text></Col>
                                <Col span={3}> <Text> Selling Price</Text></Col>
                                <Col span={3}> Type</Col>
                                <Col span={3}> Value</Col>
                                <Col span={2}><Text>%</Text></Col>
                                <Col span={2}><Text>Discount</Text></Col>
                                <Col span={2}><Text>Amount</Text></Col>
                                <Col span={3}><Text>Action</Text></Col>
                            </Row>
                            <Divider />
                            {tableData.map((product, key) => {

                                let { name, selling_price, product_id, discounted_price, percentage, amount, value } = product;
                                return (
                                    <Row key={key} gutter={[16, 16]}>
                                        <Col span={2}> <Checkbox onChange={() => handleCheck(product_id)}></Checkbox> </Col>
                                        <Col span={4}> <Text>{name}</Text></Col>
                                        <Col span={3}><Text> {selling_price} </Text></Col>
                                        <Col span={3}>
                                            <Select defaultValue="1" onChange={(value) => handleTypeChange(value, product_id)}>
                                                <Option value="1">Percentage</Option>
                                                <Option value="2">Amount</Option>
                                            </Select>
                                        </Col>
                                        <Col span={3}>
                                            <Input type="number" onChange={(v) => handleChange(v.target.value, product_id)} />
                                        </Col>
                                        <Col span={2}>{percentage}</Col>
                                        <Col span={2}>{amount}</Col>
                                        <Col span={2}><Text> {discounted_price}</Text></Col>
                                        <Col span={3}><Button onClick={() => handleDelete(product)}>Delete</Button></Col>
                                    </Row>
                                )
                                // < OfferProduct key = { key } product = { product } setData = { handleChildData } data = { tableData } />
                            })}
                            <Form.Item>
                                <Space>
                                    <Button htmlType='submit' type="primary">Apply Offer</Button>
                                    <Button onClick={() => history.goBack()} type="primary">Cancel</Button>
                                </Space>
                            </Form.Item>
                        </Col>
                        <Col span={3}></Col>

                    </Row>}

            </Form>

        </>
    );
};


export default OfferForm