import React, { useState, useEffect } from 'react';
import { Table,Typography, Select, Row, Col } from 'antd';
import { useHistory } from "react-router-dom";

const { Title } = Typography;
const { Option } = Select;

const columns = [
  {
    title: 'Product',
    dataIndex: 'product',
    key: 'product',

  },
  {
    title: 'Stock',
    dataIndex: 'stock',
    key: 'stock',

  },
  {
    title: 'Cost Price',
    dataIndex: 'costvalue',
    key: 'costvalue',
  },
  {
    title: 'Selling Price',
    dataIndex: 'saleprice',
    key: 'saleprice',

  },
  
  {
    title: 'Profit',
    dataIndex: 'costvalue',
    key: 'costvalue',
  }
];


export default function Stock() {

  const [dataSource, setDataSource] = useState([]);
  const [init, setInit] = useState(false);
  let history = useHistory();

  const addCategory = () => {
    history.push({ pathname: "/categories/form" });
  }

  return (
      <Row>
        <Col span={24}>
          <Table
            dataSource={dataSource}
            columns={columns}
            // rowSelection={{
            //   type: 'checkbox',
            // }}
            // footer={() => 'Footer'}
            rowKey="index"
          />
        </Col>
      </Row>
  )
}