import axios from 'axios';
import { SERVER_API_URL } from '../constants';

export const GlobalVariable = Object.freeze({
    APP_VERSION: '1.0.0',
    BASE_API_URL: `${SERVER_API_URL}/v1/`,
    BASE_IMAGE_URL: SERVER_API_URL,
});

class AjaxService {

    getImageUploadUrl() {
        return GlobalVariable.BASE_API_URL + 'api/file/upload';
    }

    generateUrl(url) {
        return GlobalVariable.BASE_API_URL + url;
    }

    get(url, data) {
        return this.executeRequest(url, data, 'GET');
    }

    post(url, data) {
        return this.executeRequest(url, data, 'POST');
    }
    put(url, data) {
        return this.executeRequest(url, data, 'PUT');
    }
    delete(url, data) {
        return this.executeRequest(url, data, 'DELETE');
    }

    async executeRequest(url, data, type) {
        //store.dispatch(showLoader());

        let headers = {
            'Accept': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}`
        };

        const uri = this.generateUrl('api/' + url);

        let options = {
            method: type,
            url: uri,
            headers: headers,
            data: null,
        }

        if (type === 'GET') {
            options.params = data;
        } else if (type === 'POST' || type === 'PUT') {
            options.data = data;
        }


        return axios(options).then(response => {
            //store.dispatch(hideLoader());
            return response;
        }).catch(function (error) {
            /*
            store.dispatch(hideLoader());
            
            if (uri.includes("ogin") || uri.includes("egister")) {
                ToastService.show('Invalid phone or password');
            } else {
                ToastService.show('Network error. Check your internet connection.');
            }
            */
        });
    }

    async getAccessToken(username, password) {

        let data = {
            client_id: '1',
            client_secret: 'tSn7W5GVEO3xz9WJgrIRW40JwLmblmr35LePPegg',
            username: username,
            password: password,
            grant_type: 'password'
        };

        const uri = SERVER_API_URL + '/oauth/token';

        let options = {
            method: 'POST',
            url: uri,
            data: data,
        }

        return axios(options).then(response => {
            //store.dispatch(hideLoader());
            return response;
        }).catch(function (error) {
            //store.dispatch(hideLoader());
            //ToastService.show('Network error. Check your internet connection.');
        });
    }
}

const ajaxService = new AjaxService();

export default ajaxService;