import React, { useState, useEffect } from "react";
import {
  Table,
  Space,
  Tag,
  Tooltip,
  Form,
  Row,
  Col,
  notification,
  Card,
  DatePicker,
  Input,
  Menu,
  Select,
  Button,
  Dropdown,
  message,
} from "antd";
import { useHistory } from "react-router-dom";
import ajaxService from "../../services/ajax-service";
import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { useWindowDimensions } from "../../hooks";
import {
  EditOutlined,
  EllipsisOutlined,
  SettingOutlined,
} from "@ant-design/icons";
const { RangePicker } = DatePicker;
const { Option } = Select;

export default function Orders() {
  const { width } = useWindowDimensions();
  const isMobile = width <= 768;
  const [dataSource, setDataSource] = useState([]);
  const [orderNumber, setOrderNumber] = useState("");
  const [customer, setCustomer] = useState("");
  const [customerName, setCustomerName] = useState("");
  const [StatusOrder, setStatus] = useState("");
  const [dateRange, setDateRang] = useState("");

  const history = useHistory();
  const orderStatus = ["Confirmed", "Processing", "On The Way", "Complete"];

  const columns = [
    {
      title: "Order Number",
      dataIndex: "order_number",
      key: "order_number",
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Customer",
      dataIndex: "name",
      key: "name",
      render: (text, record) => (
        <>
          <span>{record.name}</span>
          <br />
          <span>{record.email}</span>
          <br />
          <span>{record.phone}</span>
        </>
      ),
    },
    {
      title: "Shipping",
      dataIndex: "shipping",
      key: "shipping",
      ellipsis: {
        showTitle: false,
      },
      render: (shipping) => (
        <Tooltip placement="topLeft" title={shipping}>
          AUD {Number(shipping)?.toFixed(2)}
        </Tooltip>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text) => (
        <Tag color={text.length > 5 ? "geekblue" : "green"}>
          {text.toUpperCase()}
        </Tag>
      ),
    },

    {
      title: "Shipping Address",
      dataIndex: "shipping_address",
      key: "shipping_address",
    },

    {
      title: "Total",
      dataIndex: "total",
      key: "total",
      render: (total) => (
        <Tooltip placement="topLeft" title={total}>
          AUD {Number(total)?.toFixed(2)}
        </Tooltip>
      ),
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      // align: 'left',

      render: (text, record) => (
        <Space>
          <Dropdown
            overlay={
              <Menu>
                {orderStatus.map((status, key) => (
                  <Menu.Item
                    onClick={() => changeStatus(record, status)}
                    key={key}
                  >
                    {status}
                  </Menu.Item>
                ))}
              </Menu>
            }
            placement="bottomCenter"
          >
            <Button>Status</Button>
          </Dropdown>
          <Button
            onClick={() =>
              history.push({ pathname: "/orders/form/" + record.id })
            }
          >
            View
          </Button>
        </Space>
      ),
    },
  ];

  const handleSearch = async () => {
    var formData = new FormData();

    if (orderNumber !== "") {
      formData.append("orderNumber", orderNumber);
    }
    if (StatusOrder !== "") {
      formData.append("StatusOrder", StatusOrder);
    }
    if (customer !== "") {
      formData.append("customer", customer);
    }
    if (dateRange !== "") {
      formData.append("dateRange", dateRange);
    }
    if (customerName !== "") {
      formData.append("customerName", customerName);
    }
    const response = await ajaxService.post("orders", formData);

    if (response.status !== undefined) {
      setDataSource(response.data);
    } else {
      notification.open({
        message: "No Order Found...",
        icon: <CloseCircleOutlined style={{ color: "red" }} />,
      });
    }
  };
  const clear = () => {
    initialize();
    setOrderNumber("");
    setCustomer("");
    setStatus("");
    setDateRang("");
    setCustomerName("");
  };

  const changeStatus = async (record, status) => {
    let data = {
      id: record.id,
      status,
    };
    const response = await ajaxService.put("status/" + record.id, data);
    if (response.status === 200) {
      notification.open({
        message: "Order Status Updated...",
        icon: <CheckCircleOutlined style={{ color: "#108ee9" }} />,
      });
      // setDataSource(response.data);
      window.location.reload();
    } else {
      notification.open({
        message: "Order Status Error...",
        icon: <CloseCircleOutlined style={{ color: "red" }} />,
      });
    }
  };

  const initialize = async () => {
    const response = await ajaxService.get("orders");
    if (response != undefined) {
      // const { status, data } = response;

      if (response.status === 200) {
        setDataSource(response.data);
      }
    }
  };
  useEffect(() => {
    initialize();
  }, []);

  const renderMobile = () => {
    if (isMobile) {
      return (
        <>
          {dataSource.map((order, index) => {
            return (
              <Card
                key={index}
                hoverable
                style={{
                  width: "100%",
                }}
                actions={[
                  <Dropdown
                    overlay={
                      <Menu>
                        {orderStatus.map((status, key) => (
                          <Menu.Item
                            onClick={() => changeStatus(order, status)}
                            key={key}
                          >
                            {status}
                          </Menu.Item>
                        ))}
                      </Menu>
                    }
                    placement="bottomCenter"
                  >
                    <Button>Status</Button>
                  </Dropdown>,
                  <Button
                    onClick={() =>
                      history.push({ pathname: "/orders/form/" + order.id })
                    }
                  >
                    View
                  </Button>,
                ]}
              >
                <Card.Meta
                  title={`Order Number: #${order.order_number}`}
                  description={order.date}
                />
                <div style={{ marginTop: 10 }}>
                  <span>Customer</span>
                  <br />
                  <span>{order.name}</span>
                  <br />
                  <span>{order.email}</span>
                  <br />
                  <span>{order.phone}</span>
                </div>
                <div style={{ marginTop: 10 }}>
                  <span>Shipping Address</span>
                  <p>{order.shipping_address}</p>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: 10,
                  }}
                >
                  <span>Status</span>
                  <Tag
                    color={order.status.length > 5 ? "geekblue" : "green"}
                    style={{ marginRight: 0 }}
                  >
                    {order.status.toUpperCase()}
                  </Tag>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: 10,
                  }}
                >
                  <span>Shipping</span>
                  <span>AUD {order.shipping}</span>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: 10,
                  }}
                >
                  <span>Total</span>
                  <span>AUD {order.total}</span>
                </div>
              </Card>
            );
          })}
        </>
      );
    }
  };

  return (
    <>
      <Form layout={"vertical"}>
        <Row gutter={16}>
          <Col md={6} xs={24}>
            <Form.Item label="Order Number">
              <Select
                onChange={(v) => setOrderNumber(v)}
                showSearch
                allowClear
                placeholder="Select Order"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {dataSource.map((order) => {
                  return (
                    <Option
                      value={order.order_number}
                      key={"order-" + order.id}
                    >
                      {order.order_number}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col md={6} xs={24}>
            <Form.Item label="Order Status">
              <Select
                allowClear
                placeholder="Select Order Status"
                onChange={(v) => setStatus(v)}
              >
                {orderStatus.map((status) => {
                  return (
                    <Option value={status} key={"status-" + status}>
                      {status}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col md={6} xs={24}>
            <Form.Item label="Customer">
              <Select
                showSearch
                allowClear
                placeholder="Select Customer"
                onChange={(v) => setCustomer(v)}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {dataSource.map((customer) => {
                  return (
                    <Option
                      value={customer.name}
                      key={"customer-" + customer.id}
                    >
                      {customer.name}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col md={6} xs={24}>
            <Form.Item label="Date Range">
              <RangePicker value={dateRange} onChange={(e) => setDateRang(e)} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col md={6} xs={24}>
            <Form.Item label="Customer Filter">
              <Input
                value={customerName}
                onChange={(e) => setCustomerName(e.target.value)}
              />
            </Form.Item>
          </Col>
          <Col md={6} xs={24}>
            <Form.Item label="&nbsp;">
              <Space>
                <Button onClick={handleSearch}>Search</Button>
                <Button onClick={clear}>Clear</Button>
              </Space>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <Row>
        <Col span={24}>
          {!isMobile && <Table dataSource={dataSource} columns={columns} />}
          {renderMobile()}
        </Col>
      </Row>
    </>
  );
}
