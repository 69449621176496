import React, { useState, useEffect } from 'react';
import { Table, Space, Typography, Button, Modal ,Avatar,notification} from 'antd';
import { useHistory } from "react-router-dom";
import ajaxService from '../../../services/ajax-service';
import { CheckCircleOutlined, CloseCircleOutlined,DeleteOutlined  } from '@ant-design/icons';

const { Title,Text } = Typography;
export default function Brand() {

  const [dataSource, setDataSource] = useState([]);
  const [showModal, setShowModal] = useState(false);

  let history = useHistory();

  const columns = [
  
    // {
    //   title: 'ID',
    //   dataIndex: 'id',
    //   key: 'id',
    //   fixed: 'left',
    // },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',

    },
    {
      title: 'Image',
      dataIndex: 'image',
      key: 'image',
      render: (image) => <Avatar shape="square" size={64} src={image} />


    },
    {
      title: 'Active',
      dataIndex: 'active',
      key: 'active',
      render: (active, row) => <Text>{active == 1 ? 'Yes' : 'No'}</Text>,
      // render: (active, row) => <Switch size={"small"} onChange={(active) => toggleActive(active, row)} />,

    },
    {
      title: 'Products',
      dataIndex: 'products',
      key: 'products',

    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      fixed: 'right',
      width: 100,
      render: (text, record) => (
        <Space size='small'>
          <Button onClick={() => history.push({ pathname: "/brands/form/" + record.id })}  >Edit</Button>
          <Button onClick={() => openDeleteModal(record.id)}>Delete</Button>
        </Space>
      ),
    },
  ];



  const deleteCategory = async () => {
    let response = await ajaxService.delete('brands/' + localStorage.getItem('brand'));
    setShowModal(false)
    if (response.status === 200) {
      initialize();
      notification.open({ message: 'Brand Deleted Successfully...', icon: <DeleteOutlined  style={{ color: '#108ee9' }} /> })
    } else {
      notification.open({ message: 'Brand Not Deleted...', icon: <CloseCircleOutlined style={{ color: 'red' }} /> })

    }
  }
  const openDeleteModal = (id) => {
    localStorage.setItem('brand', id);
    setShowModal(true)
  }
  const addBrand = () => {
    history.push({ pathname: "/brands/form/0" });
  }
  const initialize = async () => {
    const response = await ajaxService.get('brands');

    const { status, data } = response;

    if (status === 200) {
      setDataSource(data);
    }
  }

  const renderHeader = () => {
    return (
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Title level={3}>Brand</Title>
        </div>
        <Space size="small" >
          <Button type="primary" onClick={() => history.push({ pathname: "/brands/form/0" })}  >Add Brand</Button>
        </Space>
      </div>
    );
  }
  useEffect(() => {
    initialize();
  }, [])
  return (
    <div>
      <Table
        dataSource={dataSource}
        columns={columns}
        title={() => renderHeader()}
      />
      <Modal
        title="Delete Brand"
        centered
        visible={showModal}
        onCancel={() => setShowModal(false)}
        footer={[
          <Button key="delete" onClick={() => deleteCategory()}>
            Delete
          </Button>
          ,
          <Button key="back" onClick={() => setShowModal(false)}>
            Close
          </Button>
        ]}
      >
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <p>Are you sure you want to delete this brand?</p>
        </div>
      </Modal>
    </div>
  )


}



