import React from 'react';
import { Layout} from 'antd';
import MainHeader from './header';

const { Content, Footer } = Layout;

export default function Main({ children }) {
    return (
        <Layout style={{ minHeight: '100vh' }}>
            {/* <Sidebar /> */}
            <Layout className="site-layout-background" style={{ padding: 0 }}>
                <MainHeader />
                <Content >
                    <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
                        {children}
                    </div>
                </Content>
                <Footer style={{ textAlign: 'center' }}>Copyright © {new Date().getFullYear()} OhadTech.com</Footer>
            </Layout>
        </Layout>
    );
}