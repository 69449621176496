import React, { useState, useEffect, useCallback } from 'react';
import { Form, Input, Button, notification, Row, Col, Checkbox, Select, Space } from 'antd';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import ajaxService from '../../../services/ajax-service';
import { useHistory, useParams } from "react-router-dom";
import ImageUpload from '../../../components/image-upload';


const { Option } = Select;
const { TextArea } = Input;


const CategoryForm = () => {

    let history = useHistory();
    let { id } = useParams();
    const [form] = Form.useForm();

    const [categories, setCategories] = useState([]);
    const [fileList, setFileList] = useState([]);

    let initialValues = {
        description: '',
        image: '',
        meta_description: '',
        meta_title: '',
        name: '',
        parent_id: '',
        active : false
    };

    const onChange = ({ fileList: newFileList }) => {
        setFileList(newFileList);
        if (newFileList[0] !== undefined && newFileList[0].response !== undefined) {
            const file = newFileList[0].response.path;
            form.setFieldsValue({
                image: file
            });
        }
    };

    const onFinish = async (values) => {
        let link = '';

        let { file } = values;

        if (file !== undefined) {

            let { response } = file.file;

            if (response) {
                let { success, path } = response;

                if (success) {
                    link = path
                }
            }
        }

        let data = {
            description: values.description,
            image: link,
            meta_description: values.meta_description,
            meta_title: values.meta_title,
            name: values.name,
            parent_id: values.parent_id,
            active: values.active
        }

        if (id == 0) {
            const response = await ajaxService.post('categories', data);
            if (response.status === 200) {
                history.push({ pathname: "/categories" });
                notification.open({ message: 'Category Added Successfully...', icon: <CheckCircleOutlined style={{ color: '#108ee9' }} /> })
            } else {
                notification.open({ message: 'Category Not Added...', icon: <CloseCircleOutlined style={{ color: 'red' }} /> })
            }
        } else {

            if (data.image === '') {
                data.image = fileList[0].url;
                data.active = values.active

            }
            const response = await ajaxService.put('categories/' + id, data);
            if (response.status === 200) {
                history.push({ pathname: "/categories" });
                notification.open({ message: 'Category Updated Successfully...', icon: <CheckCircleOutlined style={{ color: '#108ee9' }} /> })
            } else {
                notification.open({ message: 'Category Not Updated...', icon: <CloseCircleOutlined style={{ color: 'red' }} /> })

            }
        }
    };

    const onFinishFailed = errorInfo => {
        console.log('Failed:', errorInfo);
    };

    const loadCategories = useCallback(async () => {
        const response = await ajaxService.get('categories');
        if (response !== undefined) {
            const { status, data } = response;

            if (status === 200) {
                setCategories(data);
            }
        }
    }, [])

    const loadData = useCallback(async (id) => {
        if (id > 0) {
            let response = await ajaxService.get('categories/' + id);

            if (response.data != null) {
                form.setFieldsValue({ ...response.data });
                form.setFieldsValue({ parent_id: parseInt(response.data.parent_id) })

                let imageItem = {
                    uid: response.data.image,
                    name: 'image.png',
                    status: 'done',
                    url: response.data.image,
                    preview: false
                }
                setFileList([imageItem]);
            }
        }
    }, [])

    useEffect(() => {
        loadCategories();
        loadData(id);
    }, []);

    return (
        <>
            <Form
                layout={'vertical'}
                initialValues={initialValues}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                form={form}
            >
                <Row gutter={16} >
                    <Col span={6}></Col>
                    <Col span={12}>
                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item
                                    label="Name"
                                    name="name"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Category name is required',
                                        },
                                    ]}
                                >
                                    <Input placeholder="category name" />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="Parent Category" name="parent_id">
                                    <Select
                                        allowClear
                                        showSearch
                                        placeholder="Select parent category"
                                    >
                                        {categories.map(category => {
                                            return <Option value={category.id} key={"category-" + category.id} >{category.name}</Option>
                                        })}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item label="Meta Title" name="meta_title" rules={[
                                    {
                                        required: true,
                                        message: 'Meta Title is required',
                                    },
                                ]}>
                                    <Input placeholder="meta title" />
                                </Form.Item>
                            </Col>

                            <Col span={12}>
                                <Form.Item label="Meta description" name="meta_description" rules={[
                                    {
                                        required: true,
                                        message: 'Meta description is required',
                                    },
                                ]} >
                                    <Input placeholder="meta description" />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <Form.Item label="Upload Image" name="file">
                                    <ImageUpload onChange={onChange} fileList={fileList} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Form.Item name="active" valuePropName="checked">
                                <Checkbox>Active</Checkbox>
                            </Form.Item>
                        </Row>
                        <Row>
                            <Form.Item>
                                <Space>
                                    <Button type="primary" htmlType="submit">Submit</Button>
                                    <Button onClick={() => history.goBack()} type="primary">Cancel</Button>
                                </Space>
                            </Form.Item>
                        </Row>
                    </Col>

                    <Col span={6}></Col>
                </Row>
            </Form>

        </>
    );
};


export default CategoryForm;