import React from "react";

import { Link, useHistory } from "react-router-dom";
import { Layout, Menu } from "antd";
import {
  LoginOutlined,
  BarChartOutlined,
  AimOutlined,
  ShoppingCartOutlined,
  SoundOutlined,
  ReadOutlined,
  AppstoreOutlined,
  SettingOutlined,
  BookOutlined,
} from "@ant-design/icons";
import { useWindowDimensions } from "../hooks";

const { Header } = Layout;
const { SubMenu } = Menu;

export default function MainHeader() {
  let history = useHistory();
  const { width } = useWindowDimensions();
  const isMobile = width <= 768;

  const logout = () => {
    localStorage.removeItem("token");
    history.push({ pathname: "/" });
  };

  const renderContent = () => {
    if (isMobile) {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            background: "#003366",
            color: "#FFF",
            paddingLeft: 20,
            paddingRight: 20,
          }}
        >
          <Link to={"/"}>Orders</Link>
          <span onClick={logout}>Logout</span>
        </div>
      );
    } else {
      return (
        <Menu
          mode="horizontal"
          style={{ background: "#003366", color: "#FFF" }}
        >
          <Menu.Item key="1" icon={<ShoppingCartOutlined />}>
            <Link to={"/"}>Orders</Link>
          </Menu.Item>
          {/* <Menu.Item key="2" icon={<AimOutlined />} ><Link to={'/brands'}  >Brands</Link></Menu.Item>
                <Menu.Item key="3" icon={<ReadOutlined />}><Link to={'/categories'}  >Categories</Link></Menu.Item> */}
          <Menu.Item key="4" icon={<AppstoreOutlined />}>
            <Link to={"/products"}>Products</Link>
          </Menu.Item>
          <Menu.Item key="5" icon={<SoundOutlined />}>
            <Link to={"/offer"}>Offer</Link>
          </Menu.Item>
          <Menu.Item key="6" icon={<SettingOutlined />}>
            <Link to={"/setting"}>Setting</Link>
          </Menu.Item>
          <Menu.Item key="7" icon={<BookOutlined />}>
            <Link to={"/blogs"}>Blogs</Link>
          </Menu.Item>
          <Menu.Item
            key="8"
            onClick={logout}
            style={{ float: "right" }}
            icon={<LoginOutlined />}
          >
            Logout
          </Menu.Item>
        </Menu>
      );
    }
  };

  return <Header>{renderContent()}</Header>;
}
