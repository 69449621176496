import React from 'react';
import { useParams } from "react-router-dom";
import { Form, Input, Button, Row, Col, Switch, Space, Select } from 'antd';

const { Option } = Select;


const Prunable = () => {
    let { id } = useParams();

    const normFile = e => {
        console.log('Upload event:', e);

        if (Array.isArray(e)) {
            return e;
        }

        return e && e.fileList;
    };

    return (
        <Form
            layout={'vertical'}
        >
            <Row gutter={16} >
                <Col span={6}></Col>
                <Col span={12} className="gutter-row" >
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item label="Table Name" >
                                <Input placeholder="table name" disabled defaultValue="Product" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="Make this model prunable"  >
                                <Switch checkedChildren="Yes" unCheckedChildren="No" defaultChecked />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={6}>
                            <Form.Item label="Column" required >
                                <Input placeholder="column name" />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="Condition" required >
                                <Select defaultValue="=" allowClear>
                                    <Option value="<"> {"<"}  </Option>
                                    <Option value="<="> {"<="} </Option>
                                    <Option value="="> {"="} </Option>
                                    <Option value=">"> {">"} </Option>
                                    <Option value=">="> {">="} </Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="Value" required >
                                <Input placeholder="value" defaultValue="now()->subMonth()" />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="Add prunning method"  >
                                <Switch checkedChildren="Yes" unCheckedChildren="No" defaultChecked />
                            </Form.Item>
                        </Col>
                    </Row>

                </Col>
                <Col span={6}></Col>
            </Row>
            <Row gutter={16}>
                <Col span={6}></Col>
                <Col span={4}>
                    <Form.Item>
                        <Space size="small">
                            <Button type="primary">Submit</Button>
                            <Button type="primary">Back</Button>
                        </Space>
                    </Form.Item>
                </Col>
                <Col span={18} ></Col>
            </Row>
        </Form>
    )
}

export default Prunable;