import React from 'react'
import { Upload } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import ajaxService from '../services/ajax-service';

const ImageUpload = ({ onChange, fileList }) => {

    return (
        <Upload
            action={ajaxService.getImageUploadUrl}
            multiple={true}
            listType="picture-card"
            fileList={fileList}
            onChange={onChange}
            method={"post"}
            name="image"
            headers={{
                'Accept': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }}
        >
            {fileList.length >= 8 ? null : <div>
                <PlusOutlined />
                <div className="ant-upload-text">Upload</div>
            </div>}
        </Upload>
    )
}

export default ImageUpload