import React from 'react';
import { useParams } from "react-router-dom";
import { Form, Input, Button, Row, Col, Switch, Space, Select } from 'antd';

const { Option } = Select;


const EloquentModel = () => {
    let { id } = useParams();

    const normFile = e => {
        console.log('Upload event:', e);

        if (Array.isArray(e)) {
            return e;
        }

        return e && e.fileList;
    };

    return (
        <Form
            layout={'vertical'}
        >
            <Row gutter={16} >
                <Col span={4}></Col>
                <Col span={12} className="gutter-row" >

                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item label="Model Name" required >
                                <Input placeholder="model name" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="Table Name" >
                                <Input placeholder="table name" disabled defaultValue="Product" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item label="Primary Key" required >
                                <Input placeholder="primary key" defaultValue="id" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="Connection" >
                                <Select defaultValue="mysql" allowClear>
                                    <Option value="mysql">MySQL</Option>
                                    <Option value="sqlite">SQLite</Option>
                                    <Option value="pgsql">PostgreSQL</Option>
                                    <Option value="sqlsrv">SQL Server</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item label="Create At"  >
                                <Input placeholder="created_at" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="Updated At"  >
                                <Input placeholder="updated_at" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item label="Date Format"  >
                                <Input placeholder="U" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="Serialized Date Format"  >
                                <Input placeholder="U" />
                            </Form.Item>
                        </Col>
                    </Row>
                </Col>
                <Col span={4} className="gutter-row">
                    <Form.Item label="Primary Key Type"  >
                        <Switch checkedChildren="Integer" unCheckedChildren="String" defaultChecked />
                    </Form.Item>

                    <Form.Item label="Incrementing Key"  >
                        <Switch checkedChildren="Yes" unCheckedChildren="No" defaultChecked />
                    </Form.Item>

                    <Form.Item label="Enable Timestamps"  >
                        <Switch checkedChildren="Yes" unCheckedChildren="No" defaultChecked />
                    </Form.Item>

                    <Form.Item label="Enable Soft Delete"  >
                        <Switch checkedChildren="Yes" unCheckedChildren="No" defaultChecked />
                    </Form.Item>

                </Col>
                <Col span={4}></Col>
            </Row>
            <Row gutter={16}>
                <Col span={4}></Col>
                <Col span={4}>
                    <Form.Item>
                        <Space size="small">
                            <Button type="primary">Submit</Button>
                            <Button type="primary">Back</Button>
                        </Space>
                    </Form.Item>
                </Col>
                <Col span={20} ></Col>
            </Row>
        </Form>
    )
}

export default EloquentModel;