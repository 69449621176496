import React, { useState, useEffect } from 'react';
import ajaxService from '../../services/ajax-service';
import { Table, Space, Button, Typography, Modal, Divider } from 'antd';
import { CheckCircleTwoTone, CloseCircleTwoTone } from '@ant-design/icons';
import { useHistory } from "react-router-dom";

const { Title } = Typography;

const renderIcon = (value) => {
    return value ? <CheckCircleTwoTone twoToneColor="#52c41a" className="small-icon" /> : <CloseCircleTwoTone twoToneColor="#ededed" className="small-icon" />
}


const dataSource = [
    {
        key: '1',
        model: true,
        table: 'Product',
        prunable: false,
        product_id: '1',
        name: 'Gel Ink Pen',
        sku: 'GIP00001',
        category: 'Mobiles',
        image: 'Raheel',
        active: true,
        brand: 'HP',
        stock: 25,
        selling_price: 10,
        compare_price: 12,
        index: 0,
    },
    {
        key: '2',
        product_id: '2',
        table: 'Category',
        name: 'Avery Easy Peel Address Label',
        sku: 'AEPDL01',
        category: 'Electronics',
        image: 'Raheel',
        stock: 25,
        selling_price: 10,
        compare_price: 12,
        active: false,
        brand: 'HP',
        index: 1,
    },
    {
        key: '3',
        product_id: '3',
        table: 'Brand',
        name: 'Lenovo A7000a',
        sku: 'LA70000',
        category: 'Electronics',
        stock: 25,
        selling_price: 10,
        compare_price: 12,
        image: 'Raheel',
        active: true,
        brand: 'HP',
        index: 2,
    },
];

const ApplicationManager = () => {
    let history = useHistory();
    const [showModal, setShowModal] = useState(false);

    const columns = [
        {
            title: 'Table',
            dataIndex: 'table',
            key: 'table',
        },
        {
            title: 'Model',
            dataIndex: 'model',
            key: 'model',
            render: (text) => (
                renderIcon(text)
            ),
        },
        {
            title: 'Columns',
            dataIndex: 'columns',
            key: 'columns',
            render: (text) => (
                renderIcon(text)
            ),
        },
        {
            title: 'Prunable',
            dataIndex: 'prunable',
            key: 'prunable',
            render: (text) => (
                renderIcon(text)
            ),
        },
        {
            title: 'Observers',
            dataIndex: 'observers',
            key: 'observers',
            render: (text) => (
                renderIcon(text)
            ),
        },
        {
            title: 'Events',
            dataIndex: 'events',
            key: 'events',
            render: (text) => (
                renderIcon(text)
            ),
        },
        {
            title: 'Scopes',
            dataIndex: 'scopes',
            key: 'scopes',
            render: (text) => (
                renderIcon(text)
            ),
        },
        {
            title: 'Relations',
            dataIndex: 'relations',
            key: 'relations',
            render: (text) => (
                renderIcon(text)
            ),
        },/*
        {
            title: 'Resource',
            dataIndex: 'resource',
            key: 'resource',
            render: (text) => (
                renderIcon(text)
            ),
        },*/
        {
            title: 'Custom Attributes',
            dataIndex: 'custom_attributes',
            key: 'custom_attributes',
            render: (text) => (
                renderIcon(text)
            ),
        },/*
        {
            title: 'Labels',
            dataIndex: 'labels',
            key: 'labels',
            render: (text) => (
                renderIcon(text)
            ),
        },*/
        {
            title: 'Scanrios',
            dataIndex: 'scanrios',
            key: 'scanrios',
            render: (text) => (
                renderIcon(text)
            ),
        },
        /*
            {
                title: 'Accessors & Mutators',
                dataIndex: 'accesors_mutators',
                key: 'accesors_mutators',
                render: (text) => (
                    renderIcon(text)
                ),
            },
            {
                title: 'Permissions',
                dataIndex: 'permissions',
                key: 'permissions',
                render: (text) => (
                    renderIcon(text)
                ),
            },*/
        {
            title: 'Actions',
            dataIndex: 'actions',
            key: 'actions',
            fixed: 'right',
            className: 'drag-visible',
            width: 100,
            render: (text, record) => (
                <Space size="middle">
                    <Button type="primary" onClick={() => setShowModal(true)}  >Model</Button>
                    <Button type="primary" >Edit</Button>
                    <Button type="primary" >Delete</Button>
                </Space>
            ),
        },
    ];

    const getTables = async () => {
        let response = await ajaxService.get('tables');

        console.log(response);
    }

    const addTable = () => {

    }

    const openPage = (route, id) => {
        history.push({
            pathname: route + '/' + id,
        });
    }

    useEffect(() => {
        getTables();
    }, []);

    return (
        <div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Title level={3}>Eloquent Manager</Title>
                <Button type="primary" onClick={() => addTable()} >Add New Table</Button>
            </div>
            <Table dataSource={dataSource} columns={columns} />

            <Modal
                title="Customize Eloquent Model"
                centered
                visible={showModal}
                onCancel={() => setShowModal(false)}
                footer={[
                    <Button key="back" onClick={() => setShowModal(false)}>
                        Close
                    </Button>,
                ]}
            >
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Button onClick={() => openPage('eloquent-model', 1)} >Basic Model</Button>
                    <Button onClick={() => openPage('prunable', 1)} >Prunable</Button>
                    <Button onClick={() => openPage('observers', 1)} >Observers</Button>

                </div>
                <Divider orientation="left"></Divider>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Button onClick={() => console.log('model')} >Events</Button>
                    <Button onClick={() => console.log('model')} >Columns</Button>
                    <Button onClick={() => console.log('model')} >Scopes</Button>

                </div>
                <Divider orientation="left"></Divider>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Button onClick={() => console.log('model')} >Relations</Button>
                    <Button onClick={() => console.log('model')} >Custom Attributes</Button>
                    <Button onClick={() => console.log('model')} >Scanrios</Button>
                </div>
            </Modal>
        </div>
    )
}

export default ApplicationManager;