import React, { useState, useEffect } from 'react';
import { Form, notification, Input, Row, Col, Select, Typography, Button, InputNumber } from 'antd';
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import ajaxService from '../../services/ajax-service';
import ImageUpload from '../../components/image-upload';
const { Option } = Select;
const { Title } = Typography
const successNotificationWithIcon = type => {
    notification[type]({ message: 'Setting Updated' });
};

const errorNotificationWithIcon = type => {
    notification[type]({ message: 'Unable to save settings. Please fill required fields.' });
};

export default function Setting() {

    const [fileList, setFileList] = useState([]);
    const [imagesList, setImagesList] = useState([]);
    const [products, setProducts] = useState([]);
    const [filterProduct, setfilterProduct] = useState([]);
    const [ticker, setTicker] = useState('')
    const [form] = Form.useForm();

    const onChange = ({ fileList: newFileList }) => {
        setFileList(newFileList);
        let list = []

        newFileList.map((item) => {
            if (item.response !== undefined) {
                list.push({
                    uid: item.response.path,
                    name: 'image.png',
                    status: 'done',
                    url: item.response.path,
                    preview: false
                })
            }
        })
        setImagesList(list)
    };

    const onFinish = async (values) => {

        let imageData = []

        imagesList.map((item) => {
            if (item.url !== '') {
                imageData.push(item.url)
            }
        })

        let list = [...imageData];

        fileList.map((item) => {
            if (item.url !== undefined) {
                list.push(item.url)
            }
        });

        let {
            sections,
            minimum_order,
            minimum_distance,
            latitude,
            longitude,
            charges_within,
            charges_outof,
        } = values;

        let data = {
            url: list,
            sections: sections,
            minimum_order: minimum_order,
            minimum_distance: minimum_distance,
            latitude: latitude,
            longitude: longitude,
            charges_within: charges_within,
            charges_outof: 0,
        }

       const response = ajaxService.post('settings', data);

       if (response) {
           successNotificationWithIcon('success')
       } else {
           errorNotificationWithIcon('error')
       }

    };

    const loadProducts = async () => {
        const response = await ajaxService.get('products');

        const { status, data } = response;

        if (status === 200) {
            setProducts(data);
        }
    }

    const loadData = async () => {

        const response = await ajaxService.get('settings');

        if (response.data != null) {

            form.setFieldsValue({ ...response.data.data });
            form.setFieldsValue({ mainCategory: parseInt(response.data.data.mainCategory) })

            const section = []

            response.data.data.sections.map((v) => {
                section.push({ title: v.title, products: v.products })
            })

            form.setFieldsValue({ sections: section })

            let { latitude, longitude, minimum_distance, minimum_order, charges_within, charges_outof } = response.data.data;

            form.setFieldsValue({
                minimum_order: parseFloat(minimum_order),
                minimum_distance: parseFloat(minimum_distance),
                latitude: parseFloat(latitude),
                longitude: parseFloat(longitude),
                charges_within: parseFloat(charges_within),
                charges_outof: parseFloat(charges_outof)
            });

            let images = []

            response.data.images.map((v) => {

                let imageItem = {
                    uid: v.id,
                    name: 'image.png',
                    status: 'done',
                    url: v.url,
                    preview: false
                }
                images.push(imageItem)
            })

            setFileList(images);
        }
    }

    useEffect(() => {
        loadProducts()
        loadData()
    }, [])

    useEffect(() => {

        const saveTicker = async (ticker) => {

            if (ticker.length > 0) {
                let data = {
                    ticker: ticker,
                }

                ajaxService.post('ticker/save', data);
            }
        }

        saveTicker(ticker)

    }, [ticker])

    return (
        <Form
            layout={'vertical'}
            onFinish={onFinish}
            // initialValues={initialize}
            form={form}>
            <Row>
                <Col span={3}></Col>
                <Col span={18}>
                    <Form.Item name='ticker' label="Moving Ticker">
                        <Input placeholder="" onBlur={(e) => setTicker(e.target.value)} value={ticker} />
                    </Form.Item>
                    <Form.Item label="Upload Banner Images" name="file">
                        <ImageUpload onChange={onChange} fileList={fileList} />
                    </Form.Item>
                    <>
                        <Form.List name="sections">
                            {(fields, { add, remove }) => (
                                <>
                                    {fields.map(({ key, name, ...restField }) => (
                                        <>
                                            <Row key={key} gutter={16}>
                                                <Col span={6}>
                                                    <Form.Item name={[name, 'title']} label="Title">
                                                        <Input placeholder="" />
                                                    </Form.Item>
                                                    <Form.Item   {...restField} name={[name, 'type']} >
                                                        <Input type='hidden' value='1' />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={16}>
                                                    <Form.Item
                                                        {...restField} name={[name, 'products']}
                                                        rules={[{ required: true }]}
                                                        label="Products">
                                                        <Select
                                                            mode="multiple"
                                                            placeholder="Select Product"
                                                            style={{ width: '100%' }}
                                                        >
                                                            {filterProduct.length > 0 ?
                                                                filterProduct.map(product => {
                                                                    return <Option value={product.id} key={"product-" + product.id} >{product.name}</Option>
                                                                })
                                                                :
                                                                products.map(product => {
                                                                    return <Option value={product.id} key={"product-" + product.id} >{product.name}</Option>
                                                                })
                                                            }
                                                        </Select>
                                                    </Form.Item>
                                                </Col>
                                                <Col span={2}>
                                                    <Form.Item>
                                                        <label style={{ display: 'block' }}>&nbsp;</label>
                                                        <Button style={{ margin: '8px' }} onClick={() => remove(name)} ><DeleteOutlined /></Button>
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                            <hr />
                                        </>
                                    ))}

                                    <Form.Item>
                                        <Button type="dashed" onClick={() => add()} icon={<PlusOutlined />}>
                                            Add Section
                                        </Button>
                                    </Form.Item>
                                </>
                            )}
                        </Form.List>
                    </>
                    <Title level={4}>Shipping Charges</Title>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item name='minimum_order' label='Free shipping over amount' rules={[{ required: true }]}>
                                <InputNumber style={{ width: '80%' }} placeholder='Amount in AUD' />
                            </Form.Item>
                            <span>Specify minimum order amount for free shipping</span>
                        </Col>
                        <Col span={12}>
                            <Form.Item name='minimum_distance' label='Free shipping under distance'
                                rules={[{ required: true }]}>
                                <InputNumber style={{ width: '80%' }} placeholder='Distance in KM' />
                            </Form.Item>
                            <span>Specify distance range for free shipping</span>
                        </Col>
                    </Row>
                    <hr/>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item name='charges_within' label='Sipping charges within city'
                                rules={[{ required: true }]}>
                                <InputNumber style={{ width: '80%' }} placeholder='Amount in AUD' />
                            </Form.Item>
                            <span>Specify default shipping charges within city</span>
                        </Col>
                        <Col span={12}>
                            {/* <Form.Item name='charges_outof' label='Shipping charges out of city'
                                rules={[{ required: true }]}>
                                <InputNumber style={{ width: '80%' }} placeholder='Amount in AUD' />
                            </Form.Item>
                            <span>Specify default shipping charges out of city</span> */}
                        </Col>
                    </Row>
                    <hr/>
                    <Title level={4}>Store Location</Title>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item name='latitude' label='Latitude'
                                rules={[{ required: true }]}>
                                <InputNumber style={{ width: '80%' }} placeholder='Latitude' />
                            </Form.Item>
                            <span>Sensitive information</span>
                        </Col>
                        <Col span={12}>
                            <Form.Item name='longitude' label='Longitude'
                                rules={[{ required: true }]}>
                                <InputNumber style={{ width: '80%' }} placeholder='Longitude' />
                            </Form.Item>
                            <span>Sensitive information</span>
                        </Col>
                    </Row>
                    <Form.Item>
                        <Button htmlType='submit' type='primary'>Save</Button>
                    </Form.Item>
                </Col>
                <Col span={3}></Col>
            </Row>
        </Form >
    )
}